import { Link } from "react-router-dom";
import MostLikedPosts from "../../helper-components/MostLikedPosts";
import Sidebar from "../../main/Sidebar";
import { getPostDocs, getTopCategories } from "../../../firebase/firebase-app";
import { limit, orderBy, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import PostPreview from "../../helper-components/PostPreview";
import Post from "../../../interfaces/PostInterface";
import Category from "../../../interfaces/CategoryInterface";

function HomeFeed({ headerTop }: { headerTop: string }) {
    const [categories, setCategories] = useState<Category[] | null>(null);

    const [posts, setPosts] = useState<any[]>([]);

    async function fetchNewPosts() {
        // if reached bottom, fetch new posts.
        const docs = await getPostDocs(where("deleted", "==", false), orderBy("timestamp", "desc"), limit(3));

        if (docs?.length) {
            const data = docs.map((doc) => doc.data()) as Post[];
            setPosts(data);
        }
    }

    async function fetchTopCategories() {
        // if reached bottom, fetch new posts.
        const docs = await getTopCategories(7);

        if (docs?.length) {
            const data = docs.map((doc) => doc) as Category[];
            setCategories(data);
        }
    }

    useEffect(() => {
        // if there are still more posts to load
        fetchNewPosts();
        fetchTopCategories();
    }, []);

    return (
        <div className="container max-w-full">
            <div className="flex gap-12 max-w-[1336px] mx-auto">
                <section className="grow pt-12">
                    {posts.map((post) => (
                        <PostPreview post={post} key={post.id} />
                    ))}
                </section>

                <Sidebar headerTop={headerTop} classes="h-[calc(65vh-57px)]">
                    <div className="pt-12">
                        <div className="pb-6 border-b border-b-neutral-200 dark:border-b-neutral-700">
                            <h2 className="poppins-semibold text-black dark:text-white mb-4 text-lg">
                                Discover Intriguing Topics
                            </h2>

                            <div className="text-[15px] text-grey flex flex-wrap gap-5">
                                {categories && categories.length && categories.map((cat) => (
                                    <Link
                                        to={`/tag/${cat.name}`}
                                        key={cat.searchName}
                                        className="text-[13px] poppins-medium rounded-lg py-[4px] px-4 bg-white dark:bg-darkBgPrimary text-[#333333] dark:text-neutral-300"
                                    >
                                        {cat.name}
                                    </Link>
                                ))}
                            </div>
                        </div>

                        {/*<footer className="py-6 flex flex-wrap text-grey text-sm child:mr-6 child:mb-2">
                            <button>Help</button>
                            <button>Status</button>
                            <button>Writers</button>
                            <button>Blog</button>
                            <button>Careers</button>
                            <button>Privacy</button>
                            <button>Terms</button>
                            <button>About</button>
                            <button>Text to speech</button>
                                </footer>*/}
                    </div>
                </Sidebar>
            </div>
        </div>
    );
}

export default HomeFeed;
