// const firebaseConfig = {
//   apiKey: "",
//   authDomain: "",
//   projectId: "",
//   storageBucket: "",
//   messagingSenderId: "",
//   appId: "",
//   measurementId: "",
// };

const firebaseConfig = {
  apiKey: "AIzaSyAGhzoMUjiGHdqekZSE5Pt78PJ9_kW4Xqo",
  authDomain: "averagegeniuses-dev.firebaseapp.com",
  projectId: "averagegeniuses-dev",
  storageBucket: "averagegeniuses-dev.appspot.com",
  messagingSenderId: "359271795534",
  appId: "1:359271795534:web:f30994a13ce90d7dc5ec1f",
  measurementId: "G-DYL6E5YZ5R",
};

export default firebaseConfig;
