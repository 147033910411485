import { useEffect } from "react";

import SnipetPost from "../../interfaces/SnipetPostInterface";
import { Link } from "react-router-dom";
import getLinkForPost from "../../helper-functions/getLinkForPost";


export default function TrendingPosts({
    trendingPosts,
}: {
    trendingPosts: SnipetPost[] | null;
}) {
    
    return (
        <div className="bg-white dark:bg-darkBgPrimary dark:text-white rounded-md mb-5 p-4 flex flex-col gap-5">
            <p className=" text-[16px] mb-2 poppins-semibold">Trending Posts <i className="text-sm fa fa-bolt fa-fw text-blue-500"></i></p>
            {trendingPosts?.map(post => {
                
                
                return (
                    <Link
                        to={post.link}
                        className="flex gap-2 text-[12px] items-center poppins-medium"
                    >
                        <img
                            src={post.thumbnail ? post.thumbnail : 'https://picsum.photos/80/80/?blur'}
                            alt=""
                            className="h-[35px] w-[35px] rounded-md"
                        />
                        <div>
                            <p className="line-clamp-2">
                                {post.title}
                            </p>
                        </div>
                    </Link>
                )
            })}
        </div>
    )
}