import { Link, useNavigate } from "react-router-dom";
import {
    addPost,
    getImageUrl,
    sendNotificationToUsers,
    getTopCategories
} from "../../../firebase/firebase-app";
import { ChangeEvent, useContext, useState, useEffect } from "react";
import UserContext from "../../../UserContext";
import getRandomId from "../../../helper-functions/getRandomId";
import { serverTimestamp } from "firebase/firestore";
import calculateReadingTime from "../../../helper-functions/calculateReadingTime";
import getLinkForPost from "../../../helper-functions/getLinkForPost";
import Category from "../../../interfaces/CategoryInterface";

export default function HomePageHeader() {
    const [title, setTitle] = useState("");
    const { user } = useContext(UserContext);
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState<string[] | null>(null);

    const navigate = useNavigate();

    useEffect(()=> {
        const getTopics = async () => {
            const topics = await getTopCategories(5);
            const frmtTopics: string[] = [];
            topics.forEach(tp=>frmtTopics?.push(tp.name));
            setCategories(frmtTopics);
        }
        getTopics();
    },[]);

    async function handlePublishPost(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (!user?.uid) throw new Error("User does not exist.");
        if (loading) return;

        setLoading(true);

        const postId = getRandomId(12);
        const imageUrl = file
            ? await getImageUrl(file, `posts/${postId}/thumbnail`)
            : "";
        const linkSafeTitle = title
            .toLowerCase()
            .replace(/[\W_]+/g, " ")
            .trim()
            .replace(/ /g, "-");

        await addPost({
            title,
            description: "",
            blogContents: "",
            plainBlogContents: "",
            topics: [],
            lowercaseTopics: [],
            authorUid: user.uid,
            deleted: false,
            timestamp: serverTimestamp(),
            readingTimeInMinutes: calculateReadingTime(title, "", ""),
            thumbnail: imageUrl || "",
            likes: {},
            likeCount: 0,
            id: postId,
            link: `/${user.username}/posts/${linkSafeTitle}-${postId}`,
            organizationId: "",
            excludeFromQuery: false
        });

        // send notification to all followers
        sendNotificationToUsers(user.followers, {
            message: "published a new post",
            uid: user.uid,
            timestamp: new Date(),
            id: getRandomId(12),
            type: 'newPost',
            link: `/${user.username}/posts/${linkSafeTitle}-${postId}`,
            typeId: postId
        });

        setLoading(false);

        // redirect to the new post that was created
        navigate(getLinkForPost(user.username, title, postId));
    }

    function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
        const { files } = e.target;

        // only use first file/image
        if (!files?.length || !files[0].type.match("image.*")) {
            setFile(null);
        } else {
            setFile(files[0]);
        }
    }

    return (
        <div className="flex flex-col lg:flex-row gap-4 w-screen lg:w-full overflow-x-hidden px-4 pb-5 lg:px-10 dark:text-white border-b border-stone-200">
            <div className="lg:flex-1 flex flex-col justify-between order-2 lg:order-1">
                <p className="leading-3 text-[16px] poppins-semibold">Categories</p>
                <div className="text-sm py-1 flex  gap-7 items-end">
                    <div className="flex gap-4 lg:gap-7 py-1 overflow-x-auto">
                        {categories?.map(cat => (
                            <Link
                                to={`/tag/${cat}`}
                                className="text-primary  poppins-regular"
                            >
                                {cat.toUpperCase()}
                            </Link>
                        ))}
                    </div>

                    <Link 
                        className="flex flex-nowrap justify-center items-center flex-row min-h-[25px] min-w-[25px] border-[1px] rounded-full border-black dark:border-white "
                        to="/search-topics">
                        <i className="fa fa-plus"></i>
                    </Link>
                    
                </div>
            </div>
            <div className="order-1 lg:order-2">
                <div className="flex bg-white dark:bg-darkBgPrimary p-3 items-center rounded-md">
                    <form
                        className="flex items-center gap-3 text-[#757575]"
                        onSubmit={handlePublishPost}
                    >
                        <i
                            className="fa fa-regular fa-circle-user text-2xl dark:text-white"
                            aria-hidden="true"
                        ></i>
                        <div className=" bg-surface dark:bg-darkBgSecondary rounded-md shadow-sm flex-1 lg:w-[250px]">
                            <input
                                type="text"
                                required
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Share a quick post ..."
                                className="p-2 bg-transparent focus:outline-none text-sm w-full  placeholder-primary dark:text-white "
                            />
                        </div>
                        <input
                            disabled={loading}
                            onChange={handleFileChange}
                            id="fileInput"
                            type="file"
                            className=" hidden"
                        />
                        <button
                            type="button"
                            disabled={loading}
                            onClick={() => {
                                document.getElementById("fileInput")?.click();
                            }}
                        >
                            <i
                                className={`fa fa-camera text-2xl ${
                                    file ? "text-primary" : ""
                                }`}
                                aria-hidden="true"
                            ></i>
                        </button>
                        <button
                            type="button"
                            disabled={loading}
                            onClick={() => {
                                document.getElementById("fileInput")?.click();
                            }}
                        >
                            <i
                                className={`fa fa-paperclip text-1xl ${
                                    file ? "text-primary" : ""
                                }`}
                                aria-hidden="true"
                            ></i>
                        </button>
                        <button
                            type="submit"
                            className={`text-xs bg-primaryDark text-white py-[5px] px-3 rounded-md ml-2`}
                            disabled={loading}
                        >
                            {loading ? (
                                <i
                                    className={`fa fa-spin fa-spinner w-[30px] text-1xl`}
                                    aria-hidden="true"
                                ></i>
                            ) : (
                                "Post"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
