import { useCallback, useEffect, useState } from "react";
import { debounce, getOrganizationDocs, getPostById, getTopOrganizations, searchOrganizations } from "../../../firebase/firebase-app";
import Post from "../../../interfaces/PostInterface";
import UserContext from "../../../UserContext";
import PostPreview from "../../helper-components/PostPreview";
import ScrollerItems from "../../helper-components/ScrollerItems";
import { Link } from "react-router-dom";
import Organization from "../../../interfaces/OrganizationInterface";
import InfiniteScroll from "react-infinite-scroller";
import { QueryDocumentSnapshot, endBefore, limit, orderBy, startAfter } from "firebase/firestore";
import Spinner from "../../helper-components/Spinner";

export default function Organizations() {
  const [searchTerm, setSearchTerm] = useState("");
  const [organizations, setOrganizations] = useState<Organization[] | null>(null);
  const [allOrganizations, setAllOrganizations] = useState<Organization[] | null>(null);
  const [results, setResults] = useState<string[]>([]);
  const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot | null>(null);
  const [hasMore, setHasMore] = useState(true);

  async function fetchNewOrganizations(dyn?: any) {
    // if reached bottom, fetch new posts.
    const docs = await getOrganizationDocs(
      orderBy('title'),
      startAfter(lastDoc || 0),
      limit(3)
    );
    // if no more posts
    if (!docs.length) {
      setHasMore(false);
    } else {
      setLastDoc(docs[docs.length - 1]);

      const data = docs.map((doc) => doc.data()) as Organization[];

      onOrgzChange(data, dyn);
    }
  }

  function onOrgzChange (orgz: any, dyn: any) {
    if (typeof dyn == 'boolean' && dyn) setAllOrganizations(orgz);
    else setAllOrganizations((allOrganizations || []).concat(orgz));
  }


  const debounceSearch = useCallback(debounce(async (searchText: string) => {
    if (!searchText.trim()) {
      setResults([]);
      return;
    }
    const catResult = await searchOrganizations(searchText);
    let matchingCategories: string[] = [];
    catResult.forEach((cat) => {
        matchingCategories.push(cat.name);
    });
    setResults(matchingCategories);
  }, 300), []);

  useEffect(() => {
    debounceSearch(searchTerm);
  }, [searchTerm, debounceSearch]);

  useEffect(() => {
    async function getLatestOrgs() {
      const topics = await getTopOrganizations(5);
      const frmtTopics: Organization[] = [];
      topics.forEach(tp=>frmtTopics?.push({
        title: tp.title,
        description: tp.description,
        thumbnail: tp.thumbnail,
        authorUid: tp.authorUid,
        timestamp: tp.timestamp,
        followerCount: tp.followerCount,
        theme: tp.theme,
        id: tp.id,
        link: tp.link,
        followers: []
      }));
      setOrganizations(frmtTopics);
    }

    getLatestOrgs();
    fetchNewOrganizations(true);
  }, []);

  // if (allOrganizations == null) return null;
  
  return (
    <div className="mx-6 mt-12">
      <div className="max-w-[728px] mx-auto">
        <h1 className="poppins-semibold text-[42px] break-all mb-10 max-md:text-[22px] text-center">
          Explore Organizations
        </h1>

        <ScrollerItems className="w-full mb-2 justify-center trending__org">
          {organizations?.map(org => (
              <Link
                  to={`/organization/${org.link}`}
                  className="text-primary"
              >
                  {org.title.toUpperCase()}
              </Link>
          ))}
          <Link 
              className="flex flex-nowrap justify-center items-center flex-row min-h-[25px] min-w-[25px] border-[1px] rounded-full border-black dark:border-white"
              to="/new-organization">
              <i className="fa fa-plus"></i>
          </Link>
        </ScrollerItems>

        {/* <div className="flex items-center justify-center h-64">
          
          <div 
            className={`flex items-center bg-white dark:bg-darkBgPrimary rounded-md shadow-sm w-full`}
          >
            <input
                type="text"
                placeholder="Search Organization" 
                autoComplete="off"
                className="pl-2 py-5 bg-transparent focus:outline-none text-sm w-full dark:text-white"
            />
            <button type="submit">
                <i className="fa-solid fa-magnifying-glass text-gray-400 ml-2 mr-3 pr-4" />
            </button>
          </div>
        </div> */}

        <InfiniteScroll
          loadMore={fetchNewOrganizations}
          hasMore={hasMore}
          loader={<Spinner className="w-8 h-8 mx-auto" key={0} />}
        >
          {allOrganizations?.length ? allOrganizations.map((org) => (
            <div className="flex flex-col mt-8 pb-8 border-b border-stone-200">
              <div className="grow flex justify-between">
                  <div className="flex flex-col gap-2">
                    <div className="grow flex mt-3">
                      <Link
                          to={org.link}
                          className="text-lighterblack dark:text-white grow"
                      >
                        <div className="pb-2">
                            <h2 className="  poppins-semibold text-[22px] leading-7 line-clamp-3 max-sm:line-clamp-2 max-sm:text-base">
                                {org.title}
                            </h2>
                        </div>
                        <p className="line-clamp-3 text-black dark:text-white  poppins-regular max-sm:hidden">
                            {org.description}
                        </p>
                      </Link>
                    </div>
                  </div>
                  {org.thumbnail && (
                          <div className="ml-[60px] max-sm:ml-6">
                              <Link
                                  to={org.link}
                                  className={`block w-[80px] h-[80px] max-sm:w-[80px] max-sm:h-[56px]`}
                              >
                                  <img
                                      src={org.thumbnail}
                                      alt={org.title}
                                      className={`w-full h-full max-w-full max-h-full object-cover`}
                                  />
                              </Link>
                          </div>
                      )}
              </div>
            </div>
          )) : (
            <p className="text-grey text-center mt-4">
              Organizations will appear here.
            </p>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
}
