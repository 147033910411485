import { Link } from "react-router-dom";
import OpenModalButton from "../../helper-components/OpenModalButton";
import useScrollDirection from "../../hooks/useScrollDirection";
import SignInOptions from "../../sign_in_and_up/SignInOptions";
import SignUpOptions from "../../sign_in_and_up/SignUpOptions";
import NavbarLangButton from "../../helper-components/NavbarLangButton";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuthInstance } from "../../../firebase/firebase-app";
import { User } from "firebase/auth";

const Logo = require("../../../assets/images/logo.png");

export default function LoggedOutHeader({
    height,
    negativeTop,
}: {
    height: string;
    negativeTop: string;
}) {
    const scrollDirection = useScrollDirection();
    const top = scrollDirection === "down" ? negativeTop : "top-0";
    const authState = useAuthState(getAuthInstance());
    const [user, loading] = [authState[0] as User, authState[1]];
    return (
        <header
            className={`sticky flex transition-all duration-250 z-10 px-[2rem] bg-white dark:bg-darkBgPrimary ${height} ${top}`}
        >
            <div className="flex flex-1 items-center justify-between mx-auto">
                <Link to="/" aria-label="logo">
                    <img className="h-[45px]" src={Logo} alt="logo" />
                </Link>

                <nav className="flex items-center gap-8 text-sm">
                    {user && <NavbarLangButton />}

                    <OpenModalButton element={<SignInOptions />}>
                        Sign In
                    </OpenModalButton>

                    <OpenModalButton
                        element={<SignUpOptions />}
                        className="bg-primary text-white rounded-full px-4 py-2 transition-colors duration-300"
                    >
                        Get Started
                    </OpenModalButton>
                </nav>
            </div>
        </header>
    );
}
