import { FormEvent, useContext, useEffect, useState } from "react";
import { fetchUsers, sendNotificationToUsers } from "../../../firebase/firebase-app";
import AllUserData from "../../../interfaces/AllUserDataInterface";
import LoadingButton from "../../helper-components/LoadingButton";
import Multiselect from 'multiselect-react-dropdown';
import ModalContent from "../../modal/ModalContent";
import ModalContext from "../../modal/ModalContext";
import Organization from "../../../interfaces/OrganizationInterface";
import getRandomId from "../../../helper-functions/getRandomId";

interface UserData {
  username: string;
  uid: string;
  email?: string;
  displayName: string;
}

export default function AddUserModal({ org }: { org: Organization }) {
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(false);

  const { setModalOpen } = useContext(ModalContext);

  useEffect(()=>{
    handleSearch("");
  }, []);

  async function handleSearch(searchTrm: string) {
    try {
      setLoading(true);
      const users = await fetchUsers(searchTrm);
      
      if (!users.length) setAllUsers([]);
      else setAllUsers(users);

      setLoading(false);
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.error(err);
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit () {
    try {
      setLoading(true);
      const notification = {
        uid: org.authorUid,
        message: `You are invite to join ${org.title}.`,
        timestamp: new Date(),
        id: getRandomId(12),
        type: 'invite',
        link: `${org.link}`,
        typeId: org.id
      };
  
      const uids = selectedUsers.map(su => su.uid);
      await sendNotificationToUsers(uids, notification);
      setModalOpen(false);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  }

  function onSelect (selectedItem: any) {
    setSelectedUsers(selectedItem);
  }

  function onRemove (removedItem: any) {
    const indxToRemove = selectedUsers.findIndex(itm => itm.uid === removedItem[0].uid);
    let tmpSelectedUsers = selectedUsers;
    tmpSelectedUsers.splice(indxToRemove, 1);
    setSelectedUsers(tmpSelectedUsers);
  }

  return (
    <ModalContent className="pt-8 px-10 pb-10 max-w-[552px] w-full min-h-80 flex flex-col">
      <Multiselect 
        options={allUsers} 
        onSelect={onSelect}
        onRemove={onRemove}
        placeholder={'Type to search..'}
        selectionLimit={5}
        displayValue={'displayName'}
        loading={loading}
        onSearch={handleSearch} 
        avoidHighlightFirstOption={true}
      />
      <div className="pt-10 flex gap-4 justify-end text-sm mt-auto">
        <LoadingButton
            type="button"
            onClick={handleSubmit}
            className="flex justify-center disabled:opacity-30 h-[40px] bg-green pt-2 px-7 pb-[10px] text-white rounded-full"
            disabled={loading || selectedUsers.length === 0}
            loading={loading}
          >
            Invite Users
        </LoadingButton>
      </div>
    </ModalContent>
  );
}
