import { ChangeEvent, MouseEventHandler, useContext, useState, useCallback, useEffect } from "react";
import { serverTimestamp } from "firebase/firestore";
import {
    addPost,
    getImageUrl,
    sendNotificationToUsers,
    searchCategories,
    debounce,
    addCategory,
    addOrganization
} from "../../../firebase/firebase-app";

import UserContext from "../../../UserContext";

import calculateReadingTime from "../../../helper-functions/calculateReadingTime";
import getRandomId from "../../../helper-functions/getRandomId";
import useImagePreview from "../../hooks/useImagePreview";
import { useNavigate } from "react-router-dom";
import getLinkForPost from "../../../helper-functions/getLinkForPost";

export default function PublishOrganization({
    onGoBack,
}: {
    onGoBack: MouseEventHandler;
}) {
    const { user } = useContext(UserContext);
    const [title, setTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [theme, setTheme] = useState("default");
    const [description, setDescription] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const [topicValue, setTopicValue] = useState("");
    const [topicsArray, setTopicsArray] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState<string[]>([]);
    const [newCat, setNewCat] = useState<any[]>([]);
    const tooManyTopics = topicsArray.length >= 5;

    const navigate = useNavigate();
    const previewImage = useImagePreview(file);
    const allowedChars = /^$|^[A-Za-z0-9 _-]+$/;

    async function handleOrganization() {
        if (!user?.uid) throw new Error("User does not exist.");
        if (loading) return;

        if (validateInput()) {

            setLoading(true);

            const orgId = getRandomId(12);
            const imageUrl = file
                ? await getImageUrl(file, `posts/${orgId}/thumbnail`)
                : "";

            const linkSafeTitle = title
            .toLowerCase()
            .replace(/[\W_]+/g, " ")
            .trim()
            .replace(/ /g, "-");

            await addOrganization({
                title,
                description,
                // topics: topicsArray,
                // lowercaseTopics: topicsArray.map((t) => t.toLowerCase()),
                authorUid: user.uid,
                timestamp: serverTimestamp(),
                thumbnail: imageUrl || "",
                id: orgId,
                link: `/organization/${linkSafeTitle}-${orgId}`,
                followerCount: 0,
                theme,
                followers: []
            });

            setLoading(false);
            localStorage.removeItem("topOrgs");

            // redirect to the new organization that was created
            
            navigate(`/organization/${linkSafeTitle}-${orgId}`);
        }
    }

    function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
        const { files } = e.target;

        // only use first file/image
        if (!files?.length || !files[0].type.match("image.*")) {
            setFile(null);
        } else {
            setFile(files[0]);
        }
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
        // Reset error message if the user starts typing again
        if (errorMessage) setErrorMessage(null);
    };

    const validateInput = () => {
        if (!title.trim()) {
            setErrorMessage("Please enter a title.");
            return false;
        }
        return true;
    };

    const addTopic = (topic: string, newEntry: boolean) => {
        topic = topic.trim();
        if (tooManyTopics || !topic) return;
        // no duplicates
        const isDuplicate = topicsArray.some(
            (t) => t.toLowerCase() === topic.toLowerCase()
        );
        if (isDuplicate) return;

        setTopicsArray([...topicsArray, topic]);
        setTopicValue("");
        if (newEntry) {
            addCategory({
                name: topic,
                searchName: topic.toLowerCase()
            });
        }
    };

    const removeTopic = (topic: string) => {
        setTopicsArray((prevState) =>
            prevState.filter((filteredTopic) => filteredTopic !== topic)
        );
    };

    function onTopicChange(value: string) {
        // if last key pressed is double space or comma
        if (value.slice(-2) === "  " || value.slice(-1) === ",") {
            // remov the trailing comma before adding topic
            addTopic(value.replace(",", ""), true);
        } else if (allowedChars.test(value) && value.length <= 25) {
            let found = false;
            for (let i = 0; i < results.length; i++) {
                const element = results[i];
                if (element === value) {
                    found = true;
                    addTopic(value.replace(",", ""), false);
                    break;
                }
            }
            if (!found) setTopicValue(value);
        }
    }

    const debounceSearch = useCallback(debounce(async (searchText: string) => {
        if (!searchText.trim()) {
          setResults([]);
          return;
        }
        const catResult = await searchCategories(searchText);
        let matchingCategories: string[] = [];
        catResult.forEach((cat) => {
            matchingCategories.push(cat.name);
        });
        setResults(matchingCategories);
    }, 300), []);

    useEffect(() => {
        debounceSearch(topicValue);
      }, [topicValue, debounceSearch]);

    return (
        <div className="h-[calc(100vh-57px)] grid place-items-center font-content-sans">
            <div className="w-full max-w-3xl flex flex-wrap">
                <div className="grow p-8 flex flex-col gap-2">
                    <b className="text-[19px] text-center text-black/75 font-content-sans-bold">
                        Logo
                    </b>
                    <div className="flex flex-col gap-4 items-center justify-center">
                        <div className="w-[112px] h-[112px] bg-zinc-50 flex items-center justify-center">
                            {previewImage ? (
                                <img
                                    src={previewImage}
                                    className="w-full h-full object-cover"
                                />
                            ) : (
                                <span className="text-center text-black/50 text-[13.3px] px-2">
                                    Thumbnail (112x112px)
                                </span>
                            )}
                        </div>

                        <label
                            htmlFor="dropzone-file"
                            className="w-full text-center"
                        >
                            <div className="flex justify-center bg-gray-100 text-gray-400 py-2 rounded-md cursor-pointer">
                                <i className="fa-solid fa-cloud-arrow-up text-lg"></i>
                            </div>
                            <input
                                id="dropzone-file"
                                type="file"
                                accept="image/*"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                        </label>
                    </div>
                </div>

                <div className="p-8 flex-[50%]">
                    {/* <p className="text-[19px] text-black/75 line-clamp-1 break-all mb-4">
                        Publishing to:{" "}
                        <b className="font-content-sans-bold">
                            {user?.displayName}
                        </b>
                    </p> */}

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                            Orgnization Name
                        </label>
                        <input 
                            name="title"
                            id="title" 
                            autoComplete="off"
                            placeholder=""
                            className=" w-full rounded border border-black/10 outline-none text-black px-4 py-2 mb-4 bg-zinc-50"
                            onChange={handleTitleChange}
                            value={title}
                        />
                        {errorMessage && <p className="text-red-500 italic">{errorMessage}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                            Description
                        </label>
                        <textarea 
                            name="description"
                            id="description" 
                            autoComplete="off"
                            placeholder=""
                            className="w-full rounded border border-black/10 outline-none text-black px-4 py-2 mb-4 bg-zinc-50"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                        ></textarea>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="theme">
                            Theme
                        </label>
                        <select 
                            className="border border-black/10 outline-none text-black mb-4 bg-zinc-50 w-full py-3 px-4 pr-8 rounded leading-tight focus:outline-none" 
                            name="theme"
                            id="theme" 
                            onChange={(e) => setTheme(e.target.value)}
                            value={theme}
                            autoComplete="off"
                        >
                            <option value="blue">Deep Blue Sea</option>
                            <option value="night">Forest Night</option>
                            <option value="plum">Midnight Plum</option>
                            <option value="default">Default</option>
                        </select>
                    </div>

                    {/* <form
                        className="flex gap-2 flex-col"
                        onSubmit={(e) => {
                            e.preventDefault();
                            addTopic(topicValue, true);
                        }}
                    >
                        <label htmlFor="topic">
                            Add topics (up to 5) so readers know what your story
                            is about
                        </label>

                        <div className="flex gap-5 flex-wrap">
                            {topicsArray.map((topic) => (
                                <div
                                    className="pl-2 py-1 text-black bg-zinc-50 flex items-center gap-2 rounded-md"
                                    key={topic}
                                >
                                    <span className="text-[15px]">{topic}</span>
                                    <button
                                        type="button"
                                        className="text-lg text-black/50 pr-2  hover:text-black transition-colors duration-300"
                                        onClick={() => removeTopic(topic)}
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                        </div>
                        <input 
                            list="topics"
                            name="topic"
                            id="topic" 
                            autoComplete="off"
                            placeholder={
                                tooManyTopics
                                    ? "Maximum topics reached."
                                    : "topic 1, topic 2..."
                            }
                            className="border border-black/10 outline-none text-black px-4 py-2 mb-4 bg-zinc-50"
                            onChange={(e) => onTopicChange(e.target.value)}
                            value={topicValue}
                            disabled={tooManyTopics}
                            title={
                                tooManyTopics
                                    ? "You can only add up to 5 topics."
                                    : undefined
                            }
                        />
                        <datalist id="topics">
                            {results.map((cat) => (
                                <option value={cat}/>
                            ))}
                        </datalist>
                    </form> */}
                    <div className="flex">
                        <button
                            type="button"
                            disabled={loading}
                            className="bg-green disabled:opacity-30 hover:bg-dark-green rounded-full py-[5.5px] px-4 text-white transition-colors duration-100"
                            onClick={handleOrganization}
                        >
                            Add Organization
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
