import { Link } from "react-router-dom";
import compactNumber from "../../../helper-functions/compactNumber";
import formatDate from "../../../helper-functions/formatDate";
import UserData from "../../../interfaces/UserDataInterface";

export default function AboutPage({ user }: { user: UserData }) {
  return (
    <div className="mx-6 flex flex-col gap-6">
      {user.creationTime && (<span className="text-sm text-grey">
        Average Geniuses member since {formatDate(user.creationTime?.toDate())}
      </span>)}

      <div className="">
        <Link
          to={`/u/${user.username}/followers`}
          className="text-green text-sm"
        >
          {compactNumber(user.followers?.length || 0)} Followers
        </Link>

        <span className="mx-3">·</span>

        <Link
          to={`/u/${user.username}/following`}
          className="text-green text-sm"
        >
          {compactNumber(user.following?.length || 0)} Following
        </Link>
      </div>
    </div>
  );
}
