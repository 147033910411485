import { Link } from "react-router-dom";
import AllUserData from "../../interfaces/AllUserDataInterface";
import Dropdown from "../helper-components/Dropdown";
import ProfilePicture from "../helper-components/ProfilePicture";
import DarkModeButton from "../helper-components/DarkModeButton";

function Nav({
    user,
    onSignOut,
}: {
    user: AllUserData | null;
    onSignOut: Function;
}) {
    return (
        <nav className="flex items-center gap-3 lg:gap-9">
            <span
                className={`flex items-center text-black dark:text-white ${!user ? "opacity-50 cursor-not-allowed" : ""}`}
                title={!user ? "Anonymous users cannot write posts" : undefined}
            >
                <DarkModeButton />
            </span>

            <Link
                to="/notifications"
                className="flex items-center text-black dark:text-white"
            >
                <i className="fa-solid fa-bell text-[22px] " />
            </Link>

            <Dropdown
                buttonStyles="flex items-center hover:text-lighterblack "
                dropdownStyles="w-[264px] border-0 dark:bg-darkBgPrimary dark:border-0 dark:text-white"
            >
                <>
                    <span className="text-black mr-2 hidden lg:visible">
                        {user?.displayName || "Anonymous"}
                    </span>
                    <ProfilePicture
                        className="h-[31px] w-[31px] min-w-[31px]"
                        src={user?.photoURL}
                    />
                    {/*<i className="fa-solid fa-chevron-down text-[8.7px] thin-icon ml-2" />*/}
                </>

                <div>
                    <div className="flex flex-col py-4 child:grow child:flex child:gap-4 child:items-center child:py-2 child:px-6 ">
                        <Link to="/new-story" className="md:hidden">
                            <i className="fa-regular fa-pen-to-square thinner-icon text-[21px] min-w-[24px]" />
                            <span className="text-sm">Write</span>
                        </Link>

                        <Link to={user ? `/u/${user.username}` : "/404"}>
                            <i className="fa-regular fa-user thinner-icon text-[21px] min-w-[24px]" />
                            <p className="text-sm">Profile</p>
                        </Link>

                        <Link to={user ? "/library" : "/404"}>
                            <i className="fa-regular fa-bookmark thinner-icon text-xl min-w-[24px]" />
                            <p className="text-sm">Library</p>
                        </Link>
                    </div>

                    <div className="border-b border-b-neutral-200 dark:border-b-neutral-700" />

                    <div className="flex py-[18px]">
                        <Link
                            to={user ? "/settings" : "/404"}
                            className="grow text-sm py-[6px] px-6"
                        >
                            Settings
                        </Link>
                    </div>

                    <div className="border-b border-b-neutral-200 dark:border-b-neutral-700" />

                    <div className="py-[18px] flex">
                        <button
                            onClick={() => onSignOut()}
                            className="grow text-left py-[6px] px-6 group"
                        >
                            <div className="text-sm mb-1 ">Sign out</div>
                            <p className="text-[13px] break-all line-clamp-1">
                                {user?.email || "anonymous@mail.com"}
                            </p>
                        </button>
                    </div>
                </div>
            </Dropdown>
        </nav>
    );
}

export default Nav;
