import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    return (
        <form
            className="flex items-center bg-surface dark:bg-darkBgSecondary rounded-md shadow-sm"
            onSubmit={(e) => {
                e.preventDefault();

                if (!search) return;

                navigate(`/search?q=${search}`);
            }}
        >
            <input
                onBlur={() => setSearch("")}
                type="text"
                placeholder="Search Average Geniuses"
                className="pl-2 py-2 bg-transparent focus:outline-none text-sm w-full dark:text-white"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <button type="submit">
                <i className="fa-solid fa-magnifying-glass text-gray-400 ml-2 mr-3 pr-4" />
            </button>
        </form>
    );
};

export default SearchBar;
