import OpenModalButton from "../../helper-components/OpenModalButton";
import SignUpOptions from "../../sign_in_and_up/SignUpOptions";
import HeaderImage from "../../../assets/images/home-header.jpg";

function Hero() {
    return (
        <div className="h-[250px] sm:h-[300px] lg:h-[400px] relative">
            <img
                src={HeaderImage}
                alt="header"
                className="h-full  absolute right-0"
                style={{
                    maskImage:
                        "linear-gradient(270deg, rgba(0, 0, 0, 1) 70%, transparent 100%)",
                }}
            />
            <div className="container max-w-full flex justify-center items-center absolute top-0 left-0 bottom-0 right-0 bg-gradient-to-r from-[#70D8FF] to-[#70D8FFAA] md:to-transparent from-40% to-90%">
                <div className="grow py-6 max-w-[1336px] mx-auto">
                    <h1 className="text-3xl  tracking-tighter  mb-4 poppins-medium ">
                        Connect and Learn with Great Minds
                    </h1>
                    <p className="text-1xl max-w-[420px] mb-[30px] text-lighterblack leading-9 poppins-light">
                        Connect, Help and Explore with Average Geniuses
                    </p>

                    <OpenModalButton
                        element={<SignUpOptions />}
                        className="text-[16px] bg-primary text-white rounded-full transition duration-[0.25s] px-7 py-2 "
                    >
                        Start Reading
                    </OpenModalButton>
                </div>
            </div>
        </div>
    );
}

export default Hero;
