import { signOutUser } from "../../firebase/firebase-app";
import useScrollDirection from "../hooks/useScrollDirection";
import Nav from "./Nav";
import OpenModalButton from "../helper-components/OpenModalButton";
import SignUpOptions from "../sign_in_and_up/SignUpOptions";
import SignInOptions from "../sign_in_and_up/SignInOptions";
import Searchbar from "./Searchbar";
import { useContext } from "react";
import UserContext from "../../UserContext";
import AppSettingContext from "../../AppSettingContext";

export default function Header({
    sidebarVisible,
    onSidebarToggle,
}: {
    sidebarVisible?: boolean;
    onSidebarToggle?: () => void;
}) {
    const scrollDirection = useScrollDirection();
    const { user, isAnonymous } = useContext(UserContext);
    const { darkMode } = useContext(AppSettingContext);

    return (
        <header
            className={`sticky ${
                scrollDirection === "down" ? "-top-[75px]" : "top-0"
            } z-10 min-h-[75px] bg-white dark:bg-darkBgPrimary px-4 lg:px-10 flex items-center transition-all duration-250 w-screen lg:w-full  gap-2`}
        >
            <div className=" lg:hidden">
                <button
                    onClick={onSidebarToggle}
                    className="text-black hover:text-lightblack"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-7 w-7"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke={`${darkMode ? "#FFFFFF" : "#111111"}`}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16m-7 6h7"
                        />
                    </svg>
                </button>
            </div>
            <div className="flex  flex-1 justify-center">
                <div className=" w-full ml-1 lg:min-w-[350px] max-w-[400px] ">
                    <Searchbar />
                </div>
            </div>

            <div>
                {user || isAnonymous ? (
                    <Nav user={user} onSignOut={signOutUser} />
                ) : (
                    <div>
                        <OpenModalButton
                            element={<SignUpOptions />}
                            className="bg-green hover:bg-dark-green text-white py-[7px] px-[14px] rounded-full text-sm"
                        >
                            Sign up
                        </OpenModalButton>

                        <OpenModalButton
                            element={<SignInOptions />}
                            className="text-grey hover:text-lightblack text-[15px] ml-4"
                        >
                            Sign In
                        </OpenModalButton>
                    </div>
                )}
            </div>
        </header>
    );
}
