import { useContext, useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { likePost, deletePost } from "../../../firebase/firebase-app";
import {
    getYear
  } from "date-fns";

import Sidebar from "../../main/Sidebar";
import UserInfo from "../../helper-components/UserInfo";
import BlogMarkdownWithTitleAndDesc from "../../helper-components/BlogMarkdownWithTitleAndDesc";
import InteractionBar from "./InteractionBar";
import BlogPostHeader from "./BlogPostHeader";
import UserContext from "../../../UserContext";
import ModalContext from "../../modal/ModalContext";
import SignUpOptions from "../../sign_in_and_up/SignUpOptions";
import usePost from "../../hooks/usePost";
import LikeButton from "./LikeButton";
import OpenCommentSection from "../../helper-components/OpenCommentSection";
import CommentSection from "./CommentSection";
import useIsBottom from "../../hooks/useIsBottom";
import BlogPostFooter from "./BlogPostFooter";
import { addViewCount } from '../../../firebase/firebase-app';
import AppSettingContext from "../../../AppSettingContext";

export default function BlogPost() {
    const { user } = useContext(UserContext);
    const { setModalOpen } = useContext(ModalContext);

    const { title } = useParams();

    const postId = title?.split("-").pop() || "";
    const { post, author, comments } = usePost(postId);
    const { darkMode } = useContext(AppSettingContext);
    const history = useNavigate();

    
    useEffect(() => {
        if (!post) return;

        document.title = post.title;
        return () => {
            document.title = "Average Geniuses: Unleash Curiosity Across Tech, Art, Science, and More";
        };
    }, [post]);

    useEffect(() => {
        if (user) addViewCount(postId, user.uid);
    }, []);


    const [commentsOpen, setCommentsOpen] = useState(false);

    const bottomRef = useRef<HTMLDivElement>(null);
    const isBottom = useIsBottom(bottomRef);
    const postPath = `posts/${postId}`;

    if (!post || !author || !comments || !postId) return null;

    async function onLike() {
        if (!user) setModalOpen(true, <SignUpOptions hideAnonymousOption />);
        else await likePost(postId, user.uid);
    }

    async function deleteThisPost() {
        deletePost(postPath);
        history(-1);
    }

    return (
        <div className="max-w-[1336px] mx-auto">
            <div className="flex justify-evenly max-lg:block">
                <article className="mx-6 mb-4 grow-[0.7]">
                    <main className="mx-auto max-w-[680px] relative">
                        <div className="mb-12">
                            <BlogPostHeader author={author} post={post} />
                            <BlogMarkdownWithTitleAndDesc
                                title={post.title}
                                thumbnail={post.thumbnail}
                                description={getYear(post.timestamp?.toDate()) < 2024 ? '' : post.description}
                                blogContents={post.blogContents}
                            />
                        </div>

                        <footer ref={bottomRef}>
                            <BlogPostFooter
                                post={post}
                                comments={comments}
                                onPostLike={onLike}
                                onCommentsOpen={() => setCommentsOpen(true)}
                                user={user}
                            />
                        </footer>

                        <InteractionBar isVisible={!isBottom}>
                            <LikeButton
                                onLike={onLike}
                                currentUserLikeCount={
                                    user ? post?.likes?.[user.uid] : 0
                                }
                                likeCount={post?.likeCount}
                                disabled={user?.uid === post.authorUid}
                            />
                            <div className="border border-neutral-200 h-3 mx-4" />
                            <OpenCommentSection
                                commentsLength={comments.length}
                                onOpen={() => setCommentsOpen(true)}
                            />
                            {post.authorUid === user?.uid && (
                                <div className="border border-neutral-200 h-3 mx-4" />
                            )}
                            {post.authorUid === user?.uid && (
                                <button
                                className="flex gap-1 items-center"
                                onClick={() => deleteThisPost()}
                              >
                                <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>
                              </button>
                            )}
                        </InteractionBar>
                    </main>

                    <CommentSection
                        onClose={() => setCommentsOpen(false)}
                        post={post}
                        comments={comments}
                        commentSectionOpen={commentsOpen}
                    />
                </article>

                <Sidebar>
                    <div className="mt-10">
                        <UserInfo user={author} />
                    </div>
                </Sidebar>
            </div>
        </div>
    );
}
