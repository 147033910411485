import { useContext, useEffect, useState, useCallback } from "react";
import { debounce, getTopCategories, searchCategories } from "../../../firebase/firebase-app";
import UserContext from "../../../UserContext";
import ScrollerItems from "../../helper-components/ScrollerItems";
import { Link, useNavigate } from "react-router-dom";

export default function SearchCategory() {
  const { user } = useContext(UserContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState<String[] | null>(null);
  const [results, setResults] = useState<string[]>([]);
  const navigate = useNavigate();

  async function onTopicChange(value:string) {
    for (let i = 0; i < results.length; i++) {
      const element = results[i];
      if (element === value) {
        navigate("/tag/"+value);
          break;
      }
  }
    setSearchTerm(value);
  }

  const debounceSearch = useCallback(debounce(async (searchText: string) => {
    if (!searchText.trim()) {
      setResults([]);
      return;
    }
    const catResult = await searchCategories(searchText);
    let matchingCategories: string[] = [];
    catResult.forEach((cat) => {
        matchingCategories.push(cat.name);
    });
    setResults(matchingCategories);
  }, 300), []);

  useEffect(() => {
    debounceSearch(searchTerm);
  }, [searchTerm, debounceSearch]);

  useEffect(() => {
    async function getLatestCategories() {
      const topics = await getTopCategories(5);
      const frmtTopics: string[] = [];
      topics.forEach(tp=>frmtTopics?.push(tp.name));
      setCategories(frmtTopics);
    }

    getLatestCategories();
  }, []);

  return (
    <div className="mx-6 mt-12">
      <div className="max-w-[728px] mx-auto">
        <h1 className="poppins-semibold text-[42px] break-all mb-10 text-center max-md:text-[22px]">
        Explore Categories
        </h1>

        <ScrollerItems className="w-full mb-2 justify-center">
        {categories?.map(cat => (
            <Link
                to={`/tag/${cat}`}
                className="text-primary"
            >
                {cat.toUpperCase()}
            </Link>
        ))}
        </ScrollerItems>

        <div className="flex items-center justify-center h-64">
          
          <div 
            className={`flex items-center bg-white dark:bg-darkBgPrimary rounded-md shadow-sm w-full`}>
            <input
                list="topics"
                placeholder="Search Categories" 
                autoComplete="off"
                className="pl-2 py-5 bg-transparent focus:outline-none text-sm w-full dark:text-white"
                onChange={(e) => onTopicChange(e.target.value)}
            />
            <button type="submit">
                <i className="fa-solid fa-magnifying-glass text-gray-400 ml-2 mr-3 pr-4" />
            </button>
            {/* <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" className="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
              </button>
            </span>
            <input list="topics" 
              onChange={(e) => onTopicChange(e.target.value)}
              name="q" className="py-2 text-sm text-white bg-gray-900 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900" placeholder="Search..." autoComplete="off" /> */}
            <datalist id="topics">
                {results.map((cat) => (
                    <option value={cat}/>
                ))}
            </datalist>
          </div>
        </div>
        
      </div>
    </div>
  );
}
