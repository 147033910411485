import { Link } from "react-router-dom";

export default function TermsCondition() {
  
  return (
    <div className="mx-6 mt-12 mb-10">
      <div className="max-w-[728px] mx-auto">
        <h1 className="poppins-semibold text-[32px] break-all mb-10 max-md:text-[22px]">
        Terms and Conditions for Average Genius
        </h1>
        <p className="my-3 text-l poppins-regular">Welcome to Average Genius! These Terms and Conditions outline the rules and regulations for the use of Average Genius's Website, located at <Link to="https://www.averagegeniuses.com" className="underline" >www.averagegeniuses.com</Link>.</p>
        <p className="my-3 text-l poppins-regular">By accessing this website, we assume you accept these terms and conditions. Do not continue to use Average Genius if you do not agree to take all of the terms and conditions stated on this page.</p>

        <h2 className="poppins-semibold text-xl">1. Terms of Subscription</h2>
        <p className="my-3 text-l poppins-regular">Average Genius is a subscription-based service. Users must subscribe and pay any applicable fees to access and contribute content. The subscription terms, including fees and payment methods, are subject to change and will be updated on our website.</p>

        <h2 className="poppins-semibold text-xl">2. Content Contribution</h2>
        <p className="my-3 text-l poppins-regular">Users may submit their own blog posts to Average Genius. By submitting content, users grant Average Genius a non-exclusive, worldwide, perpetual right to use, reproduce, modify, and display the content in connection with the service.</p>

        <h2 className="poppins-semibold text-xl">3. Earnings and Payments</h2>
        <ul className="my-3">
            <li className="text-l poppins-regular"><strong>Earnings:</strong> Contributors may earn money based on the attraction their articles receive. Average Genius uses a proprietary algorithm to calculate earnings, which considers factors like page views, engagement, and subscriber feedback.</li>
            <li className="text-l poppins-regular"><strong>Bank Details:</strong> To receive payments, contributors must provide valid bank account details. Average Genius is not responsible for errors in payment due to incorrect bank information provided by the user.</li>
            <li className="text-l poppins-regular"><strong>Payment Schedule:</strong> Payments are made on a monthly basis, subject to a minimum earnings threshold. Details and thresholds are specified on our payment terms page.</li>
        </ul>

        <h2 className="poppins-semibold text-xl">4. User Conduct</h2>
        <p className="my-3 text-l poppins-regular">Users are expected to behave respectfully and responsibly, including interactions with other users and content creation. Prohibited actions include, but are not limited to:</p>
        <ul className="pb-3">
            <li className="text-l poppins-regular">Posting content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, invasive of another's privacy, or hateful.</li>
            <li className="text-l poppins-regular">Engaging in fraudulent activities.</li>
            <li className="text-l poppins-regular">Violating intellectual property rights of others.</li>
        </ul>

        <h2 className="poppins-semibold text-xl">5. Termination</h2>
        <p className="my-3 text-l poppins-regular">Average Genius reserves the right to terminate your access to the site, without cause or notice, which may result in the forfeiture and destruction of all information associated with your account. If your subscription is terminated, no refund will be provided for any remaining subscription period.</p>

        <h2 className="poppins-semibold text-xl">6. Changes to Terms</h2>
        <p className="my-3 text-l poppins-regular">Average Genius reserves the right to modify these terms and conditions at any time. We will do so by posting and drawing attention to the updated terms on the site. Your decision to continue to visit and make use of the site after such changes have been made constitutes your formal acceptance of the new terms and conditions.</p>

        <h2 className="poppins-semibold text-xl">7. Contact Us</h2>
        <p className="my-3 text-l poppins-regular">If you have any questions about these Terms and Conditions, please contact us at <Link to="mailto:info@averagegeniuses.com" className="underline">info@averagegeniuses.com</Link>.</p>
        <p className="my-3 text-l poppins-regular">These Terms and Conditions form the entire agreement between you and Average Genius concerning your use of this website, and supersede all previous agreements and understandings.</p>
      </div>
    </div>
  );
}
