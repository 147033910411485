import firebaseConfig from "./firebase-config";
import { initializeApp } from "firebase/app";
import {
    getAdditionalUserInfo,
    getAuth,
    GoogleAuthProvider,
    signInAnonymously,
    signInWithPopup,
    signOut,
    updateEmail,
    User,
    UserCredential,
} from "firebase/auth";
import {
    getFirestore,
    collection,
    query,
    where,
    orderBy,
    limit,
    getDocs,
    updateDoc,
    increment,
    doc,
    setDoc,
    addDoc,
    getDoc,
    deleteDoc,
    arrayUnion,
    arrayRemove,
    getCountFromServer,
    serverTimestamp,
    deleteField,
    Timestamp,
    startAt,
    endAt
} from "firebase/firestore";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";
import Post from "../interfaces/PostInterface";
import generateUniqueUsername from "../helper-functions/generateUniqueUsername";
import Comment from "../interfaces/CommentInterface";
import UserData from "../interfaces/UserDataInterface";
import Notification from "../interfaces/NotificationInterface";
import PrivateUserData from "../interfaces/PrivateUserData";
import Category from "../interfaces/CategoryInterface";
import Organization from "../interfaces/OrganizationInterface";

const getAuthInstance = getAuth;

const isNewUser = (user: UserCredential) =>
    getAdditionalUserInfo(user)?.isNewUser;

const getCollectionRef = (name: string) => collection(getFirestore(), name);

async function getUsernameDoc(username: string) {
    const document = await getDoc(
        doc(getFirestore(), "usernames", username.toLowerCase())
    );

    return document;
}

const isUsernameTaken = async (username: string) =>
    (await getUsernameDoc(username)).exists();

async function addUser(user: User) {
    if (!user.email || !user.displayName) return;

    const { uid, displayName, photoURL, email } = user;

    try {
        const username = await generateUniqueUsername(email);
        const lowercaseUsername = username.toLowerCase();
        const userData: UserData = {
            uid: uid,
            displayName,
            username,
            // for case-insensitive search purposes
            lowercaseUsername,
            photoURL,
            followers: [],
            following: [],
            creationTime: serverTimestamp(),
            bio: "",
            notifications: [],
            phone: "",
            location: "",
            language: [],
            bankDetails: ""
        };

        const privateUserData: PrivateUserData = {
            bookmarks: [],
            email,
        };

        await setDoc(doc(getFirestore(), "users", uid), userData);
        await setDoc(
            doc(getFirestore(), `users/${uid}/private`, "private-info"),
            privateUserData
        );

        // for checking if username is unique
        await setDoc(doc(getFirestore(), `usernames`, lowercaseUsername), {
            uid,
        });
    } catch (error) {
        console.error("Error writing to Firebase Database", error);
    }
}

async function signInWithGoogle() {
    // Sign in Firebase using popup auth and Google as the identity provider.
    const provider = new GoogleAuthProvider();
    const userCredential: UserCredential = await signInWithPopup(
        getAuthInstance(),
        provider
    );

    const newUser = isNewUser(userCredential);

    // if is new user, add user to database
    if (newUser) await addUser(userCredential.user);

    return { user: userCredential.user, newUser };
}

function getDocRef(path: string) {
    return doc(getFirestore(), path);
}
async function getDocData(path: string) {
    const document = await getDoc(doc(getFirestore(), path));

    return document?.data();
}

async function searchForDoc(
    collectionToSearch: string,
    field: string,
    equalTo: string
) {
    const docs = await getDocs(
        query(
            getCollectionRef(collectionToSearch),
            where(field, "==", equalTo),
            limit(1)
        )
    );

    if (docs.empty) return null;

    return docs.docs[0];
}

const getPostRef = (id: string) => getDocRef(`posts/${id}`);
const getUserRef = (uid: string) => getDocRef(`users/${uid}`);
const getOrgRef = (id: string) => getDocRef(`organizations/${id}`)


const getPostById = async (id: string) => getDocData(`posts/${id}`);
const getUserById = async (uid: string) => getDocData(`users/${uid}`);

const getUserDocByName = async (name: string) =>
    searchForDoc("users", "username", name);

async function updateUser(uid: string, obj: {}) {
    updateDoc(getUserRef(uid), obj);
}

// private user info. bookmarks and email
async function updatePrivateUserInfo(uid: string, obj: {}) {
    updateDoc(getUserRef(`${uid}/private/private-info`), obj);
}

async function changeUsername(
    uid: string,
    oldUsername: string,
    newUsername: string
) {
    const lowercaseUsername = newUsername.toLowerCase();
    // delete old username
    await deleteDoc(getDocRef(`usernames/${oldUsername.toLowerCase()}`));
    // set new username
    await setDoc(doc(getFirestore(), "usernames", lowercaseUsername), { uid });

    await updateUser(uid, { username: newUsername, lowercaseUsername });
}

async function changeEmail(newEmail: string) {
    const { currentUser } = getAuthInstance();
    if (!currentUser) return;

    await updateEmail(currentUser, newEmail);
    await updatePrivateUserInfo(currentUser.uid, { email: newEmail });
}

interface UserDataC {
    username: string;
    uid: string;
    email?: string;
    displayName: string;
}

async function fetchUsers(userName: string) {
    const options = userName && userName.length ? [where("lowercaseUsername", ">=", userName), where("lowercaseUsername", "<=", userName + "\uf8ff"), limit(5)] : [limit(5)];
    const q = query(getCollectionRef("users"), ...options);

    const { docs } = await getDocs(q);

    const data = docs.map(async(userDoc) => {
        const userData = userDoc.data();
        const privateInfoRef = getUserRef(`${userDoc.id}/private/private-info`);
        const privateInfoDoc = await getDoc(privateInfoRef);
        const privateInfoData = privateInfoDoc.exists() ? privateInfoDoc.data() : {};

        return {
          username: userData.username,
          uid: userDoc.id,
          email: privateInfoData.email,
          displayName: userData.displayName,
        } as UserDataC; 
    });

    const users = await Promise.all(data);

    return users;
}

async function changePhone(newPhone: string) {
    const { currentUser } = getAuthInstance();
    if (!currentUser) return;

    await updatePrivateUserInfo(currentUser.uid, { phone: newPhone });
}

async function changeBankDetails(bankDetails: string) {
    const { currentUser } = getAuthInstance();
    if (!currentUser) return;

    await updatePrivateUserInfo(currentUser.uid, { bankDetails: bankDetails });
}

async function getImageUrl(file: File, filePath: string) {
    try {
        // Upload the image to Cloud Storage.
        const newImageRef = ref(getStorage(), filePath);
        await uploadBytesResumable(newImageRef, file);

        // Generate a public URL for the file.
        return await getDownloadURL(newImageRef);
    } catch (error) {
        console.error("Error uploading image to Firebase Storage", error);
    }
}

async function addPost(post: Post) {
    try {
        await setDoc(doc(getFirestore(), "posts", post.id), post);
    } catch (error) {
        console.error("Error writing to Firebase Database", error);
    }
}

async function addOrganization(org: Organization) {
    try {
        await setDoc(doc(getFirestore(), "organizations", org.id), org);
    } catch (error) {
        console.error("Error writing to Firebase Database", error);
    }
}

async function addCategory(category: Category) {
    try {
        await addDoc(collection(getFirestore(), "categories"), category);
    } catch (error) {
        console.error("Error writing to Firebase Database", error);
    }
}

async function addComment(commentPath: string, comment: Comment) {
    setDoc(doc(getFirestore(), commentPath), comment);
}

async function updateTrending(postId: string) {
    const postRef = doc(getFirestore(), "posts", postId);
    getDoc(postRef).then((docSnap) => {
        if (docSnap.exists()) {
            updateDoc(postRef, {
                trending: increment(1)
            });
        }
    });
}

async function getPostDocs(...options: any[]) {
    const q = query(getCollectionRef("posts"), ...options);

    const { docs } = await getDocs(q);

    return docs;
}

async function getCategoryDocs(...options: any[]) {
    const q = query(getCollectionRef("categories"), ...options);

    const { docs } = await getDocs(q);

    return docs;
}

async function getOrganizationDocs(...options: any[]) {
    const q = query(getCollectionRef("organizations"), ...options);

    const { docs } = await getDocs(q);

    return docs;
}

async function getPosts(...options: any[]) {
    const docs = await getPostDocs(...options);

    return docs.map((document) => document.data());
}

async function getCategories(...options: any[]) {
    const docs = await getCategoryDocs(...options);

    return docs.map((document) => document.data());
}

async function getOrganization(...options: any[]) {
    const docs = await getOrganizationDocs(...options);

    return docs.map((document) => document.data());
}

async function getPostCount(...options: any) {
    const q = query(getCollectionRef("posts"), ...options);

    const snapshot = await getCountFromServer(q);

    return snapshot.data().count;
}

async function getPostsByUser(uid: string, ...options: any[]) {
    return getPosts(where("authorUid", "==", uid), ...options);
}

async function getRecentPosts() {
    return getPosts(orderBy('timestamp', 'desc'), limit(5));
}

async function getTrendingPosts() {
    const fiveDaysAgo = new Date(Date.now() - (5 * 24 * 60 * 60 * 1000));
    const fiveDaysAgoTimestamp = Timestamp.fromDate(fiveDaysAgo);
    return getPosts(where('timestamp', '>=', fiveDaysAgoTimestamp), orderBy("timestamp"), orderBy('trending', 'desc'), limit(5));
}

async function likePost(postId: string, userUid: string) {
    const postRef = doc(getFirestore(), "posts", postId);
    getDoc(postRef).then((docSnap) => {
        if (docSnap.exists()) {
          const postData = docSnap.data();
          const hasLiked = postData.likes && postData.likes[userUid];
          if (hasLiked) {
            // User has liked, now unlike
            updateDoc(postRef, {
              [`likes.${userUid}`]: deleteField(),
              likeCount: increment(-1),
              trending: increment(-1)
            });
          } else {
            // User has not liked, now like
            updateDoc(postRef, {
              [`likes.${userUid}`]: serverTimestamp(),
              likeCount: increment(1),
              trending: increment(1)
            });
          }
        }
    });
}

async function likeComment(commentPath: string, userUid: string) {
    const commentRef = doc(getFirestore(), commentPath);
    getDoc(commentRef).then((docSnap) => {
        if (docSnap.exists()) {
          const commentData = docSnap.data();
          const hasLiked = commentData.likes && commentData.likes[userUid];
          if (hasLiked) {
            // User has liked, now unlike
            updateDoc(commentRef, {
              [`likes.${userUid}`]: deleteField(),
              likeCount: increment(-1),
            });
          } else {
            // User has not liked, now like
            updateDoc(commentRef, {
              [`likes.${userUid}`]: serverTimestamp(),
              likeCount: increment(1),
            });
          }
        }
    });
}

function addViewCount(postId: string, userUid: string) {
    const postRef = doc(getFirestore(), "posts", postId);
    getDoc(postRef).then((docSnap) => {
        if (docSnap.exists()) {
            const postData = docSnap.data();
            const hasViewed = postData.views && postData.views[userUid];
            if (!hasViewed) {
                updateDoc(postRef, {
                    trending: increment(1),
                    [`views.${userUid}`]: serverTimestamp()
                });
            }
        }
    });
}

async function addNewFieldToPosts() {
    // const postRef = doc(getFirestore(), "posts");
    const q = query(getCollectionRef("posts"));

    const snapshot = await getDocs(q);
    snapshot.forEach((doc: any)=>{
        const docRef = getDocRef(`posts/${doc.id}`);
        const excludeFromQuery = doc.data().organizationId ? true : false;
        updateDoc(docRef, {"excludeFromQuery": excludeFromQuery});
    });
    // getDoc(postRef).then((docSnap) => {
    //     if (docSnap.exists()) {
    //         const postData = docSnap.data();
    //         postData.forEach((doc: any)=>{
    //             const docRef = getDocRef(`posts/${doc.id}`);
    //             const excludeFromQuery = doc.data().organizationId ? true : false;
    //             updateDoc(postRef, {excludeFromQuery});
    //         });
    //     }
    // });
}

const deleteComment = async (commentPath: string) =>
    deleteDoc(doc(getFirestore(), commentPath));

const deletePost = async (postPath: string) =>
    updateDoc(doc(getFirestore(), postPath), {
        deleted: true,
    });

const editComment = async (commentPath: string, commentText: string) =>
    updateDoc(doc(getFirestore(), commentPath), {
        text: commentText,
        edited: true,
    });

async function followUser(userUid: string, userToFollowUid: string) {
    updateDoc(getDocRef(`users/${userUid}`), {
        following: arrayUnion(userToFollowUid),
    });

    updateDoc(getDocRef(`users/${userToFollowUid}`), {
        followers: arrayUnion(userUid),
    });
}

async function unfollowUser(userUid: string, userToUnfollow: string) {
    updateDoc(getDocRef(`users/${userUid}`), {
        following: arrayRemove(userToUnfollow),
    });

    updateDoc(getDocRef(`users/${userToUnfollow}`), {
        followers: arrayRemove(userUid),
    });
}

async function followOrganization(orgId: string, userUid: string, id: string) {
    if (orgId) {
        // Req accepted
        updateDoc(getDocRef(`organizations/${orgId}`), {
            followers: arrayUnion(userUid),
        });
    }
    // Remove Notification
    const user = await getUserById(userUid);
    if (!user) return;
    const notiIndx = user.notifications.findIndex((nt:any)=>nt.id === id);
    if (notiIndx > -1) user.notifications.splice(notiIndx, 1);
    await updateUser(userUid, { notifications: user.notifications });
}
 
async function sendNotificationToUser(uid: string, notification: Notification) {
    const user = await getUserById(uid);

    if (!user) return;

    // limit notifications to 100
    if (user.notifications?.length > 99) user.notifications.pop();

    if (notification.type === 'invite') {
        const notiIndx = user.notifications.findIndex((nt:any) => nt.typeId === notification.typeId);
        if (notiIndx > -1) user.notification.splice(notification.typeId, 1);
    }

    user.notifications?.unshift(notification);


    await updateUser(uid, { notifications: user.notifications });
}

async function sendNotificationToUsers(
    uids: string[],
    notification: Notification
) {
    if (uids && uids.length) {
        for (const uid of uids) {
            await sendNotificationToUser(uid, notification);
        }
    }
}

async function continueAnonymously() {
    await signInAnonymously(getAuthInstance());
}

async function setThemeMode(userUid: string, isDarkMode: boolean) {
    updateDoc(getDocRef(`users/themesettings/${userUid}`), { isDarkMode });
}

// get user theme mode
async function getThemeMode(userUid: string) {
    const doc = await getDoc(getDocRef(`users/themesettings/${userUid}`));
    return doc.data()?.isDarkMode;
}

// get user location
async function updateLocation() {
    const { currentUser } = getAuthInstance();
    if (!currentUser) return;
    const location = await fetchUserLocation();

    await updatePrivateUserInfo(currentUser.uid, { location });
}
const fetchUserLocation = async () => {
    try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return {
            city: data.city,
            state: data.region,
            country: data.country_name
        };
    } catch (error) {
        console.error('There was an error fetching the user location:', error);
        return { city: "", state: "", country: "" }; // Return empty strings in case of error
    }
};

const searchCategories = async (searchText: string) => {
    const matchingCategories = await getCategories(orderBy('searchName'), where('searchName', '>=', searchText.toLowerCase()), limit(5));
    // const matchingCategories = await getCategories(orderBy('name', 'desc'), limit(5));
    return matchingCategories;
}

const searchOrganizations = async (searchText: string) => {
    const matchingOrganizations = await getOrganization(orderBy('title'), where('title', '>=', searchText.toLowerCase()), limit(5));
    // const matchingCategories = await getCategories(orderBy('name', 'desc'), limit(5));
    return matchingOrganizations;
}

const getTopCategories = async (maxLimit: number) => {
    const matchingCategories = await getCategories(orderBy('searchName'), limit(maxLimit));
    // const matchingCategories = await getCategories(orderBy('name', 'desc'), limit(5));
    return matchingCategories;
}

const getTopOrganizations = async (maxLimit: number) => {
    const matchingOrgs = await getOrganization(orderBy('title'), limit(maxLimit));
    // const matchingCategories = await getCategories(orderBy('name', 'desc'), limit(5));
    return matchingOrgs;
}

function debounce<T extends (...args: any[]) => void>(func: T, waitFor: number) {
    let timeout: NodeJS.Timeout;
  
    return function(...args: Parameters<T>) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), waitFor);
    };
}

function toCamelCase(str:string) {
    return str
      // First, replace any - or _ with a space to handle kebab-case and snake_case
      .replace(/[-_]+/g, ' ')
      // Split the string into words
      .split(' ')
      // Transform to camel case
      .map((word, index) => 
        index == 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      // Join the words back into a single string
      .join('');
  }

const signOutUser = () => signOut(getAuthInstance());

// Initialize Firebase
initializeApp(firebaseConfig);

export {
    getAuthInstance,
    signInWithGoogle,
    changeUsername,
    signOutUser,
    addUser,
    addPost,
    getImageUrl,
    isUsernameTaken,
    getPostById,
    getUserById,
    getPosts,
    getUserDocByName,
    getPostsByUser,
    getUserRef,
    updateUser,
    addComment,
    getPostRef,
    likePost,
    likeComment,
    changeEmail,
    changePhone,
    getCollectionRef,
    deleteComment,
    deletePost,
    editComment,
    followUser,
    unfollowUser,
    getPostDocs,
    getPostCount,
    sendNotificationToUsers,
    sendNotificationToUser,
    continueAnonymously,
    updatePrivateUserInfo,
    getUsernameDoc,
    setThemeMode,
    getThemeMode,
    getRecentPosts,
    addViewCount,
    getTrendingPosts,
    updateTrending,
    updateLocation,
    changeBankDetails,
    searchCategories,
    debounce,
    addCategory,
    getTopCategories,
    addOrganization,
    getOrgRef,
    addNewFieldToPosts,
    getTopOrganizations,
    toCamelCase,
    searchOrganizations,
    getOrganizationDocs,
    fetchUsers,
    followOrganization
};
