import { Link, Route, Routes, useLocation } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import LoggedOutHomepage from "./components/pages/logged_out_page/LoggedOutHomepage";
import { useAuthState } from "react-firebase-hooks/auth";
import UserContext from "./UserContext";
import {
    getAuthInstance,
    getUserRef,
    setThemeMode,
} from "./firebase/firebase-app";
import Homepage from "./components/pages/home_page/Homepage";
import useModal from "./components/modal/useModal";
import ModalContext from "./components/modal/ModalContext";
import Modal from "./components/modal/Modal";
import Header from "./components/main/Header";
import CreatePost from "./components/pages/create_post_page/CreatePost";
import AuthenticatedRoute from "./AuthenticatedRoute";
import BlogPost from "./components/pages/blog_post_page/BlogPost";
import { User } from "firebase/auth";
import ProfilePage from "./components/pages/profile_page/ProfilePage";
import Settings from "./components/pages/settings_page/Settings";
import { useDocumentData } from "react-firebase-hooks/firestore";
import PostsWithTopic from "./components/pages/posts_with_topic_page/PostsWithTopic";
import NotificationPage from "./components/pages/notification_page/NotificationPage";
import Library from "./components/pages/library_page/Library";
import SearchPage from "./components/pages/search_page/SearchPage";
import AllUserData from "./interfaces/AllUserDataInterface";
import PageNotFound from "./components/pages/404_page/PageNotFound";
import LayoutSidebar from "./components/main/LayoutSidebar";
import { useState } from "react";
import AppSettingContext from "./AppSettingContext";
import TermsCondition from "./components/pages/terms_conditions/TermsConditions";
import SearchCategory from "./components/pages/search_category/SearchCategory";
import PostsWithOrganization from "./components/pages/posts_with_organization/PostsWithOrganization";
import CreateOrganization from "./components/pages/create_organization_page/CreateOrganization";
import Organizations from "./components/pages/organization_page/Organizations";

function App() {
    const authState = useAuthState(getAuthInstance());
    const [user, loading] = [authState[0] as User, authState[1]];

    const [sidebarVisible, setSidebarVisible] = useState(
        window.innerWidth >= 903.08
    );
    const { pathname } = useLocation();

    // if user is not anonymous or signed out
    const isAuthenticated = user && !user.isAnonymous;

    // userData anyone can see
    const [userData, userDataLoading] = useDocumentData(
        isAuthenticated ? getUserRef(user.uid) : null
    );

    const [isDarkMode, setDarkMode] = useState(
        localStorage.getItem("darkMode") ? true : false
    );

    const setIsDarkMode = (isDarkMode: boolean) => {
        setDarkMode(isDarkMode);
        if (isDarkMode)
            localStorage.setItem("darkMode", isDarkMode ? "true" : "false");
        else localStorage.removeItem("darkMode");
    };

    // private user data (bookmarks, notifications, email)
    const [privateUserData, privateUserDataLoading] = useDocumentData(
        isAuthenticated ? getUserRef(`${user.uid}/private/private-info`) : null
    );

    const allUserData = (
        userData && privateUserData
            ? Object.assign({}, userData, privateUserData)
            : null
    ) as AllUserData | null;

    const { modalContent, setModalOpen, isModalOpen } = useModal();

    // hide scrollbar when modal open
    document.body.style.overflow = isModalOpen ? "hidden" : "auto";

    if (loading) return null;

    // anonymous account also counts as logged in
    const isLoggedIn = !!user;
    const isUserDataLoading = privateUserDataLoading || userDataLoading;

    return (
        <AppSettingContext.Provider
            value={{
                darkMode: isDarkMode,
                toggleDarkMode: () => setIsDarkMode(!isDarkMode),
            }}
        >
            <ModalContext.Provider
                value={{
                    modalContent,
                    setModalOpen,
                    isModalOpen,
                }}
            >
                <UserContext.Provider
                    value={{
                        user: allUserData,
                        isAnonymous: !!user?.isAnonymous,
                        loading: isUserDataLoading,
                    }}
                >
                    <Modal />
                    <div
                        className={`${
                            isUserDataLoading ? "hidden" : undefined
                        } ${isDarkMode ? "dark" : ""}`}
                    >
                        <div className="flex dark:text-white">
                            {
                                // show header anywhere except "/" path unless logged in
                                isLoggedIn && (
                                    <LayoutSidebar
                                        toggle={() => {
                                            if (window.innerWidth < 903.08)
                                                setSidebarVisible(
                                                    !sidebarVisible
                                                );
                                        }}
                                        show={sidebarVisible}
                                    />
                                )
                            }

                            <div className={`flex-1 bg-surface dark:bg-darkBgSecondary ${isDarkMode ? 'dark-mode-on' : ''}`}>
                                {
                                    // show header anywhere except "/" path unless logged in
                                    (pathname !== "/" || isLoggedIn) && (
                                        <Header
                                            sidebarVisible={sidebarVisible}
                                            onSidebarToggle={() =>
                                                setSidebarVisible(
                                                    !sidebarVisible
                                                )
                                            }
                                        />
                                    )
                                }
                                <Routes>
                                    <Route
                                        element={<PageNotFound />}
                                        path="*"
                                    />
                                    <Route
                                        element={
                                            isLoggedIn ? (
                                                <Homepage />
                                            ) : (
                                                <LoggedOutHomepage />
                                            )
                                        }
                                        path="/"
                                    />
                                    <Route
                                        element={
                                            <AuthenticatedRoute
                                                isLoggedIn={isLoggedIn}
                                            >
                                                <CreatePost />
                                            </AuthenticatedRoute>
                                        }
                                        path="/new-story"
                                    />
                                    <Route
                                        element={<SearchPage />}
                                        path="/search"
                                    />
                                    <Route
                                        element={<TermsCondition />}
                                        path="/terms-condition"
                                    />
                                    <Route
                                        element={
                                            <AuthenticatedRoute
                                                isLoggedIn={isLoggedIn}
                                            >
                                                <NotificationPage />
                                            </AuthenticatedRoute>
                                        }
                                        path="/notifications"
                                    />
                                    <Route
                                        element={
                                            <AuthenticatedRoute
                                                isLoggedIn={isLoggedIn}
                                            >
                                                <SearchCategory />
                                            </AuthenticatedRoute>
                                        }
                                        path="/search-topics"
                                    />
                                    <Route
                                        element={
                                            <AuthenticatedRoute
                                                isLoggedIn={isLoggedIn}
                                            >
                                                <Library />
                                            </AuthenticatedRoute>
                                        }
                                        path="/library"
                                    />
                                    <Route
                                        element={<BlogPost />}
                                        path=":username/posts/:title"
                                    />
                                    <Route
                                        element={<ProfilePage page="profile" />}
                                        path="u/:username"
                                    />
                                    <Route
                                        element={<ProfilePage page="about" />}
                                        path="u/:username/about"
                                    />
                                    <Route
                                        element={
                                            <ProfilePage page="followers" />
                                        }
                                        path="u/:username/followers"
                                    />
                                    <Route
                                        element={
                                            <ProfilePage page="following" />
                                        }
                                        path="u/:username/following"
                                    />
                                    <Route
                                        element={
                                            <AuthenticatedRoute
                                                isLoggedIn={isLoggedIn}
                                            >
                                                <Settings />
                                            </AuthenticatedRoute>
                                        }
                                        path="/settings"
                                    ></Route>
                                    <Route
                                        element={
                                            <AuthenticatedRoute
                                                isLoggedIn={isLoggedIn}
                                            >
                                                <Organizations />
                                            </AuthenticatedRoute>
                                        }
                                        path="/organizations"
                                    ></Route>
                                    <Route
                                        element={
                                            <AuthenticatedRoute
                                                isLoggedIn={isLoggedIn}
                                            >
                                                <PostsWithOrganization sortBy="latest" />
                                            </AuthenticatedRoute>
                                        }
                                        path="/organization/:orgName"
                                    ></Route>
                                    <Route
                                        element={
                                            <AuthenticatedRoute
                                                isLoggedIn={isLoggedIn}
                                            >
                                                <CreateOrganization />
                                            </AuthenticatedRoute>
                                        }
                                        path="/new-organization"
                                    ></Route>
                                    <Route
                                        element={
                                            <PostsWithTopic sortBy="latest" />
                                        }
                                        path="/tag/:topicName"
                                    />
                                    <Route
                                        element={
                                            <PostsWithTopic sortBy="best" />
                                        }
                                        path="/tag/:topicName/best"
                                    />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </UserContext.Provider>
            </ModalContext.Provider>
        </AppSettingContext.Provider>
    );
}

export default App;
