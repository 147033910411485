import { Link, useNavigate } from 'react-router-dom';

const GoBack: React.FC = () => {
  const history = useNavigate();

  const goBack = () => {
    history(-1);
  };

  return (
    <Link 
        to={"javascript:void(0)"}
        onClick={goBack}
        className='hover:underline hover:text-blue-500'
    >
        <i className="fa-solid fa-arrow-left-long"></i>
        <span>&nbsp;Back</span>
    </Link>
  );
};

export default GoBack;