import { Link } from "react-router-dom";
import formatDate from "../../helper-functions/formatDate";
import getLinkForPost from "../../helper-functions/getLinkForPost";
import Post from "../../interfaces/PostInterface";
import useUser from "../hooks/useUser";
import BookmarkButton from "./BookmarkButton";
import ProfilePicture from "./ProfilePicture";
import NewLikeButton from "./NewLikeButton";
import NewDislikeButton from "./NewDislikeButton";
import NewCommentButton from "./NewCommentButton";
import SignUpOptions from "../sign_in_and_up/SignUpOptions";
import { useContext, useState } from "react";
import UserContext from "../../UserContext";
import ModalContext from "../modal/ModalContext";
import { likePost } from "../../firebase/firebase-app";
import usePost from "../hooks/usePost";
import CommentSection from "../pages/blog_post_page/CommentSection";

export default function PostPreview({
    post,
    initialThumbnailSize,
    omitProfile,
}: {
    post: Post;
    initialThumbnailSize?: string;
    omitProfile?: Boolean;
}) {
    const author = useUser(post.authorUid);
    const { user } = useContext(UserContext);
    const { setModalOpen } = useContext(ModalContext);
    const isFollowing = author?.uid ? user?.following?.includes(author.uid) : false;

    const { comments, post: post1 } = usePost(post.id);

    const [commentsOpen, setCommentsOpen] = useState(false);

    if (!author) return null;
    const postLink = getLinkForPost(author.username, post.title, post.id);

    async function onLike() {
        if (!user) setModalOpen(true, <SignUpOptions hideAnonymousOption />);
        else await likePost(post.id, user.uid);
    }
    return (
        <article className="pt-10 pb-8 dark:text-white border-b border-stone-200">

            <div className="flex flex-col mt-3">
                    <div className="flex gap-2">
                        {!omitProfile && (
                            <Link to={`/u/${author.username}`}>
                                <ProfilePicture
                                    src={author.photoURL}
                                    className=" w-[30px]"
                                />
                            </Link>
                        )}

                        <div className="flex flex-col">
                            {!omitProfile && (
                                <div className="flex poppins-regular">
                                    <Link to={`/u/${author.username}`}>
                                        <p className="line-clamp-1 break-all text-sm text-black dark:text-white">
                                            {author.displayName}
                                        </p>
                                    </Link>
                                    {user?.uid !== post.authorUid && (<span className="mx-1 -mt-1">.</span>)}
                                    {user?.uid !== post.authorUid && (
                                        <Link to={`/u/${author.username}`}>
                                            <p className="line-clamp-1 break-all text-sm text-primaryDark">
                                                {isFollowing? 'UnFollow' : 'Follow'}
                                            </p>
                                        </Link>
                                    )}
                                </div>
                            )}
                            {/*<span className="text-grey text-sm whitespace-nowrap">
                                {formatDate(post.timestamp.toDate(), {
                                    relativeIfLast7Days: true,
                                    omitIfCurrentYear: true,
                                })}
                            </span>*/}
                            <span className="text-grey dark:text-[#CCCCCC] text-xs whitespace-nowrap poppins-regular">
                                {formatDate(post.timestamp?.toDate(), {
                                    relativeIfLast7Days: true,
                                    omitIfCurrentYear: true,
                                })}
                            </span>
                        </div>
                    </div>
                <div className="grow flex justify-between">
                    <div className="flex flex-col gap-2">
                        <div className="grow flex mt-3">
                            <Link
                                to={postLink}
                                className="text-lighterblack dark:text-white grow"
                            >
                                <div className="pb-2">
                                    <h2 className="  poppins-semibold text-[22px] leading-7 line-clamp-3 max-sm:line-clamp-2 max-sm:text-base">
                                        {post.title}
                                    </h2>
                                </div>
                                {post.description && post.description.length && (<p className="line-clamp-3 text-black dark:text-white  poppins-regular max-sm:hidden">
                                    {post.description.substring(0, 165)}{post.description.length>165 ? "...": ""}
                                </p>)}
                            </Link>
                        </div>
                        {user && (
                            <div className="flex items-center mt-8 max-sm:mt-4 justify-between ">
                                <div className="flex text-black dark:text-white gap-10 items-center">
                                    <NewLikeButton
                                        onLike={onLike}
                                        currentUserLikeCount={
                                            user ? post1?.likes?.[user.uid] : 0
                                        }
                                        likeCount={post1?.likeCount}
                                        disabled={user?.uid === post.authorUid}
                                    />

                                    <NewCommentButton
                                        commentsLength={comments?.length}
                                        onOpen={() => setCommentsOpen(!commentsOpen)}
                                    />
                                </div>
                                <BookmarkButton postId={post.id} />
                            </div>
                        )}
                    </div>
                    {post.thumbnail && (
                            <div className="ml-[60px] max-sm:ml-6">
                                <Link
                                    to={postLink}
                                    className={`block ${
                                        initialThumbnailSize ||
                                        "w-[112px] h-[112px]"
                                    } max-sm:w-[80px] max-sm:h-[56px]`}
                                >
                                    <img
                                        src={post.thumbnail}
                                        alt={post.title}
                                        className={`w-full h-full max-w-full max-h-full object-cover`}
                                    />
                                </Link>
                            </div>
                        )}
                </div>
            </div>

            <CommentSection
                onClose={() => setCommentsOpen(false)}
                post={post}
                comments={comments}
                commentSectionOpen={commentsOpen}
            />
        </article>
    );
}
