import { useContext, useEffect } from "react";
import UserContext from "../../../UserContext";
import ModalContext from "../../modal/ModalContext";
import UpdateUsernameModal from "./UpdateUsernameModal";
import ProfilePicture from "../../helper-components/ProfilePicture";
import UpdateProfileInfoModal from "./UpdateProfileInfoModal";
import UpdateEmailModal from "./UpdateEmailModal";
import UpdatePhoneModal from "./UpdatePhoneModal";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { Link } from "react-router-dom";
import FbLogo from "../../../assets/images/fb-logo.png";
import GoogleLogo from "../../../assets/images/google-logo.png";
import AmazonLogo from "../../../assets/images/amazon-logo.png";
import AppSettingContext from "../../../AppSettingContext";
import { updateLocation } from "../../../firebase/firebase-app";
import UpdateBankAccounts from "./UpdateBankAccount";

export default function Settings() {
    const { user } = useContext(UserContext);
    const { setModalOpen } = useContext(ModalContext);
    const settings = useContext(AppSettingContext);

    useEffect(() => {
        document.title = "Settings - Average Geniuses";
        return () => {
            document.title = "Average Geniuses: Unleash Curiosity Across Tech, Art, Science, and More";
        };
    }, []);

    if (!user) return null;

    return (
        <div className="w-full flex gap-5 flex-col lg:flex-row overflow-x-hidden">
            <div className="w-full lg:w-[300px] order-2 lg:order-1 bg-white dark:bg-darkBgPrimary dark:text-white m-2 lg:min-h-[calc(100vh-95px)] p-4 lg:p-6 flex flex-col gap-6 mb-10 lg:mb-2">
                <h2 className=" text-xl font-bold mb-2">Settings</h2>
                <Link to="/settings/account" className=" text-sm text-primary">
                    Account Preference
                </Link>

                <Link
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setModalOpen(true, <UpdatePasswordModal />);
                    }}
                    to="/settings/account"
                    className=" text-sm"
                >
                    Password and Security
                </Link>

                <Link
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setModalOpen(true, <UpdateBankAccounts user={user}/>);
                    }}
                    to="/settings/account"
                    className=" text-sm"
                >
                    Bank Account
                </Link>

                <Link to="/terms-condition" className=" text-sm">
                    Terms and Conditions
                </Link>
            </div>

            <main className="p-6 max-w-[600px] order-1 lg:order-2 w-full flex gap-8 flex-col dark:text-white">
                <div className="w-full">
                    <p className="text-2xl poppins-semibold">
                        Account Preference
                    </p>
                    <hr className=" border-[#757575] my-2" />
                    <table className=" text-sm w-full">
                        <tbody>
                            <tr>
                                <td className="py-3 font-bold align-text-top w-[40%]">
                                    Name
                                </td>
                                <td className="py-3">{user.displayName}</td>
                            </tr>
                            <tr>
                                <td className="py-3 font-bold  align-text-top">
                                    Email ID
                                </td>
                                <td className="py-3">
                                    <p>{user.email}</p>
                                    {/* <button
                                        onClick={() =>
                                            setModalOpen(
                                                true,
                                                <UpdateEmailModal user={user} />
                                            )
                                        }
                                        className="text-primary"
                                    >
                                        Add another email
                                    </button> */}
                                </td>
                            </tr>
                            <tr>
                                <td className="py-3 font-bold align-text-top">
                                    Contact
                                </td>
                                <td className="py-3">
                                    <p>{user.phone}</p>
                                    <button 
                                        className="text-primary"
                                        onClick={() =>
                                            setModalOpen(
                                                true,
                                                <UpdatePhoneModal user={user} />
                                            )
                                        }
                                    >
                                        {user.phone ? 'Update' : 'Add'} mobile number
                                    </button>
                                </td>
                            </tr>

                            <tr>
                                <td className="py-3 font-bold align-text-top">
                                    Location
                                </td>
                                <td className="py-3">
                                <p>{user.location && user.location.city ? `${user.location.city}, ${user.location.state}, ${user.location.country}`: ''}</p>
                                    <button 
                                        className="text-primary"
                                        onClick={ async() =>
                                            await updateLocation()
                                        }
                                    >
                                        {user.location && user.location.city ? 'Update' : 'Select'} location
                                    </button>
                                </td>
                            </tr>
                            {/* <tr>
                                <td className="py-3 font-bold  align-text-top">
                                    Language
                                </td>
                                <td className="py-3">
                                    <button className="text-primary">
                                        Select Language
                                    </button>
                                </td>
                            </tr> */}
                            <tr>
                                <td className="py-3 font-bold align-text-top">
                                    Dark Mode
                                </td>
                                <td className="py-3">
                                    <button
                                        onClick={() =>
                                            settings?.toggleDarkMode?.()
                                        }
                                        className=" bg-black text-white p-1 px-3 text-[10px]"
                                    >
                                        {settings.darkMode
                                            ? "Turn Off"
                                            : "Turn On"}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="w-full">
                    <p className="text-2xl poppins-semibold">
                        Connected Accounts & Contacts
                    </p>
                    <hr className=" border-[#757575] my-2" />
                    <table className=" text-sm w-full">
                        <tbody>
                            <tr>
                                <td className=" py-3 flex flex-row items-center gap-4 font-bold align-text-top w-[40%]">
                                    <img
                                        src={GoogleLogo}
                                        className="h-[20px]"
                                        alt=""
                                    />{" "}
                                    Email
                                </td>
                                <td className="py-3">
                                    <p>{user.email}</p>
                                    {/* <button className="text-primary">
                                        Disconnect
                                    </button> */}
                                </td>
                            </tr>

                            {/* <tr>
                                <td className="flex py-3  flex-row items-center gap-4 font-bold align-text-top">
                                    <img
                                        src={FbLogo}
                                        className="h-[20px]"
                                        alt=""
                                    />{" "}
                                    Facebook
                                </td>
                                <td className="py-3 ">
                                    <p>{user.email}</p>
                                    <button className="text-primary">
                                        Connect Facebook Account
                                    </button>
                                </td>
                            </tr>

                            <tr>
                                <td className="flex py-3  flex-row items-center gap-4 font-bold align-text-top">
                                    <img
                                        src={AmazonLogo}
                                        className="h-[20px]"
                                        alt=""
                                    />{" "}
                                    Amazon
                                </td>
                                <td className="py-3">
                                    <p>{user.email}</p>
                                    <button className="text-primary">
                                        Disconnect
                                    </button>
                                </td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>

                <div className="flex flex-col gap-8">
                {/*    <button
                        className="flex justify-between gap-2 group"
                        onClick={() =>
                            setModalOpen(true, <UpdateEmailModal user={user} />)
                        }
                    >
                        <span className="text-sm text-lighterblack">
                            Email address
                        </span>

                        <span className="text-grey font-content-sans text-[16.3px] group-hover:text-lightblack line-clamp-1 break-all">
                            {user.email}
                        </span>
                    </button>

                    <button
                        className="flex justify-between gap-2"
                        onClick={() =>
                            setModalOpen(true, <UpdatePasswordModal />)
                        }
                    >
                        <span className="text-sm text-lighterblack">
                            Change password
                        </span>
                        <i className="fa-solid fa-lock text-grey text-[16.3px]" />
                    </button>

                    <button
                        className="flex justify-between gap-2 group"
                        onClick={() =>
                            setModalOpen(
                                true,
                                <UpdateUsernameModal user={user} />
                            )
                        }
                    >
                        <span className="text-sm text-lighterblack">
                            Username
                        </span>
                        <span className="text-grey font-content-sans text-[16.2px] group-hover:text-lightblack line-clamp-1 break-all">
                            @{user.username}
                        </span>
                    </button>
                    */}
                    <button
                        className="flex justify-between text-sm group"
                        onClick={() =>
                            setModalOpen(
                                true,
                                <UpdateProfileInfoModal user={user} />
                            )
                        }
                    >
                        <div className="text-left">
                            <p className="text-lighterblack">
                                Profile information
                            </p>
                            <div className="text-[13px] text-grey mt-1">
                                Edit your photo, name, bio, etc.
                            </div>
                        </div>
                        <div className="flex items-center pl-2">
                            <span className="text-grey text-sm line-clamp-1 break-all group-hover:text-lightblack">
                                {user.displayName}
                            </span>

                            <ProfilePicture
                                src={user.photoURL}
                                className="w-6 h-6 ml-2"
                            />
                        </div>
                    </button>
                      </div>
            </main>

            {/*<Sidebar>
                <div className="flex flex-col">
                    <h2 className="font-sohne-bold mt-16 mb-8">
                        Suggested help articles
                    </h2>
                    <div className="text-sm flex flex-col gap-4 text-lighterblack child:text-left">
                        <button>Sign in or sign up to Average Geniuses</button>
                        <button>Your profile page</button>
                        <button>Writing and publishing your first story</button>
                        <button>
                            About Average Geniuses's distribution system
                        </button>
                        <button>Get started with the Partner Program</button>
                    </div>
                </div>
            </Sidebar>*/}
        </div>
    );
}
