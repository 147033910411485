export default function calculateDays(timestamp: number) {
    const timestampInMilliseconds = timestamp * 1000;
    const dateOfTimestamp = new Date(timestampInMilliseconds);
    const currentDate = new Date();

    // Resetting hours, minutes, seconds, and milliseconds to ensure we calculate full days difference
    currentDate.setHours(0, 0, 0, 0);
    dateOfTimestamp.setHours(0, 0, 0, 0);

    const differenceInMilliseconds = currentDate.getTime() - dateOfTimestamp.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return Math.floor(differenceInDays);
  }
  