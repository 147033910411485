import { FormEvent, useContext, useState } from "react";
import { changeBankDetails } from "../../../firebase/firebase-app";
import AllUserData from "../../../interfaces/AllUserDataInterface";
import LoadingButton from "../../helper-components/LoadingButton";
import ModalContent from "../../modal/ModalContent";
import ModalContext from "../../modal/ModalContext";

export default function UpdateBankAccounts({ user }: { user: AllUserData }) {
  const [bankDetails, setBankDetails] = useState(user.bankDetails || "");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const { setModalOpen } = useContext(ModalContext);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (bankDetails.accountNumber === user.bankDetails?.accountNumber && bankDetails.routingNumber === user.bankDetails?.routingNumber && bankDetails.bankName === user.bankDetails?.bankName) return;

    try {
      setLoading(true);

      await changeBankDetails(bankDetails);
      setModalOpen(false);
    } catch (err: unknown) {
      if (err instanceof Error) {
        setErrorMsg(err.message);
      }
    } finally {
      setLoading(false);
    }
  }
  return (
    <ModalContent className="pt-8 px-10 pb-10 max-w-[552px] w-full">
      <form onSubmit={handleSubmit} noValidate>
        <h1 className="text-lighterblack text-[22px] font-sohne-bold mb-10">
          Bank Details
        </h1>

      <div>
        <label htmlFor="bankName" className="text-[13.2px] leading-5 text-inherit">
        Bank Name
        </label>
        <input
          type="text"
          id="bankName" 
          maxLength={60}
          placeholder="Enter Bank Name"
          value={bankDetails?.bankName || ""}
          onChange={(e) => setBankDetails({...bankDetails, bankName: e.target.value})}
          className={`text-sm w-full outline-none border-b ${
            errorMsg ? "border-red-700" : "border-neutral-200 focus:border-grey"
          } py-1 pr-3 mb-3`}
        />
      </div>

      <div>
        <label htmlFor="accountNumber" className="text-[13.2px] leading-5 text-inherit">
        Bank Account Number{bankDetails?.accountNumber && (<i>: {bankDetails?.accountNumber.substr(bankDetails?.accountNumber.length-4)}</i>)}
        </label>
        <input
            type="password"
            id="accountNumber" 
            maxLength={34}
            placeholder="Enter Bank Account Number"
            value={bankDetails?.accountNumber || ""}
            onChange={(e) => setBankDetails({...bankDetails, accountNumber: e.target.value})}
            className={`text-sm w-full outline-none border-b ${
              errorMsg ? "border-red-700" : "border-neutral-200 focus:border-grey"
            } py-1 pr-3 mb-3`}
          />
      </div>

      <div>
        <label htmlFor="routingNumber" className="text-[13.2px] leading-5 text-inherit">
        Bank Routing Number
        </label>
        <input
          type="tel"
          id="routingNumber"
          placeholder="Enter Bank Routing Number"
          value={bankDetails?.routingNumber || ""}
          maxLength={9}
          onChange={(e) => setBankDetails({...bankDetails, routingNumber: e.target.value})}
          className={`text-sm w-full outline-none border-b ${
            errorMsg ? "border-red-700" : "border-neutral-200 focus:border-grey"
          } py-1 pr-3`}
        />
      </div>
        <div className="text-[13px] text-grey mt-1">
          {errorMsg ? (
            <span className="text-red-700">{errorMsg}</span>
          ) : (
            ""
          )}
        </div>
        <div className="mt-10 flex gap-4 justify-end text-sm">
          <button
            className="text-[#0f730c] border-[#0f730c] border rounded-full pt-2 px-7 pb-[10px]"
            onClick={() => setModalOpen(false)}
            type="button"
          >
            Cancel
          </button>
          <LoadingButton
            type="submit"
            className="flex justify-center disabled:opacity-30 w-[85.97px] h-[40px] bg-green pt-2 px-7 pb-[10px] text-white rounded-full"
            disabled={loading}
            loading={loading}
          >
            Save
          </LoadingButton>
        </div>
      </form>
    </ModalContent>
  );
}
