import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"
import BlogMarkdownWithTitleAndDesc from "../../helper-components/BlogMarkdownWithTitleAndDesc";
import PublishPost from "./PublishPost";
import { useLocation } from "react-router";
import GoBack from "../../helper-components/BackButton";
import TiptapEditor  from "./Tiptap";

export default function CreatePost() {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [blogContents, setBlogContents] = useState("");
    const [isEditing, setIsEditing] = useState(true);
    const [isPublishing, setIsPublishing] = useState(false);
    const location = useLocation();
    const orgId = location.state?.orgId;


    
    useEffect(() => {
        function onBeforeClose(e: BeforeUnloadEvent) {
            // if nothing was typed, close with no confirmation
            if (!title && !blogContents && !description) return;

            // confirm to close with unsaved changes
            e.preventDefault();
            e.returnValue =
                "Do you want to leave the site? Changes you made may not be saved.";
        }

        window.addEventListener("beforeunload", onBeforeClose);

        return () => window.removeEventListener("beforeunload", onBeforeClose);
    }, [blogContents, title, description]);

    return (
        <>
            <div className={isPublishing ? "block" : "hidden"}>
                <PublishPost
                    title={title}
                    description={description}
                    blogContents={blogContents}
                    orgId={orgId}
                    onGoBack={() => setIsPublishing(false)}
                />
            </div>

            <div
                className={`flex flex-col mb-12 pt-4 ${
                    isPublishing ? "hidden" : ""
                }`}
            >
                <div className="flex gap-3 text-sm mx-auto items-center">
                    <GoBack />
                    <button
                        className="flex items-center bg-lightblack px-4 py-2 text-white rounded-full"
                        onClick={() => setIsEditing(!isEditing)}
                    >
                        {isEditing ? (
                            <>
                                <i className="fa-regular fa-eye text-neutral-200 mr-2" />
                                Preview
                            </>
                        ) : (
                            <>
                                <i className="fa-regular fa-edit text-neutral-200 mr-2" />
                                Edit
                            </>
                        )}
                    </button>
                    <button
                        onClick={() => {
                            if (title) setIsPublishing(true);
                        }}
                        disabled={!title}
                        title={!title ? "You must enter a title" : undefined}
                        className="flex items-center bg-green disabled:opacity-40 text-white rounded-full px-4 py-2"
                    >
                        <i className="fa-solid fa-upload text-neutral-200 mr-2"></i>
                        Publish
                    </button>
                </div>

                <div
                    className={`flex flex-col mx-auto w-full max-w-[740px] px-5 pt-2 text-black  ${
                        !isEditing && "hidden"
                    }`}
                >
                    <input 
                        id="title" 
                        name="title" 
                        type="text"
                        autoComplete="off" 
                        className="font-title mb-3 text-[42px] leading-[52.5px] min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 text-gray" 
                        placeholder="Title" 
                        value={title}
                        maxLength={100}
                        onChange={(e) =>
                            setTitle(e.target.value.replace(/\n/g, ""))
                        }
                    />
                    
                    <input
                        id="description"
                        name="description"
                        type="text"
                        autoComplete="off"
                        placeholder="Description"
                        value={description}
                        maxLength={140}
                        onChange={(e) =>
                            setDescription(e.target.value.replace(/\n/g, ""))
                        }
                        className="font-content-serif mb-3 text-[26px] leading-[42.4px] min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 text-gray"
                    />

                    <ReactQuill 
                        theme="snow" 
                        placeholder="Tell your story..."
                        onChange={(content) => setBlogContents(content)} 
                        value={blogContents}
                        style={{
                            backgroundColor: 'white', height: '300px', borderRadius: '6px', fontSize: "21px"
                        }}
                    />

                    {/* <TiptapEditor  content={'Your content goes here'} onSubmit={setBlogContents} /> */}
                    
                </div>
                {!isEditing && (
                    <div className="max-w-[680px] mt-4 mx-auto max-sm:mx-4">
                        <div>
                            <BlogMarkdownWithTitleAndDesc
                                title={title}
                                description={description}
                                blogContents={blogContents}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
