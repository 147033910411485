import {
  useDocumentData,
} from "react-firebase-hooks/firestore";
import {
  getOrgRef
} from "../../firebase/firebase-app";

import Organization from "../../interfaces/OrganizationInterface";

export default function useOrg(orgId: string) {
  const orgRef = orgId ? getOrgRef(orgId) : null;
  const organization =  useDocumentData(orgRef)[0] as Organization;
  return { organization };
}
