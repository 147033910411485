import { arrayRemove, arrayUnion } from "firebase/firestore";
import { useContext } from "react";
import { updatePrivateUserInfo } from "../../firebase/firebase-app";
import UserContext from "../../UserContext";
import ModalContext from "../modal/ModalContext";
import SignUpOptions from "../sign_in_and_up/SignUpOptions";
import AppSettingContext from "../../AppSettingContext";

export default function BookmarkButton({ postId }: { postId: string }) {
    const { setModalOpen } = useContext(ModalContext);
    const { user, isAnonymous } = useContext(UserContext);
    const isBookmarked = user?.bookmarks?.includes(postId);
    const { darkMode } = useContext(AppSettingContext);

    const unBookmarkPost = () => {
        updatePrivateUserInfo(user!.uid, { bookmarks: arrayRemove(postId) });
    };
    const bookmarkPost = () => {
        updatePrivateUserInfo(user!.uid, { bookmarks: arrayUnion(postId) });
    };

    return (
        <button
            onClick={() => {
                if (isAnonymous || !user) {
                    setModalOpen(true, <SignUpOptions hideAnonymousOption />);
                    return;
                }

                if (isBookmarked) unBookmarkPost();
                else bookmarkPost();
            }}
            className={`text-2xl px-2 disabled:opacity-40 disabled:cursor-not-allowed`}
            disabled={isAnonymous}
            title={
                isAnonymous
                    ? "Anonymous users cannot have bookmarks"
                    : undefined
            }
        >
            {
                isBookmarked ? (<svg width="24" height="24" viewBox="0 0 24 24" fill="none"><path className={`${darkMode ? 'fill-white' : 'fill-grey'}`} d="M7.5 3.75a2 2 0 0 0-2 2v14a.5.5 0 0 0 .8.4l5.7-4.4 5.7 4.4a.5.5 0 0 0 .8-.4v-14a2 2 0 0 0-2-2h-9z" fill="#000"></path></svg>)
                    : (<svg width="24" height="24" viewBox="0 0 24 24" fill="none"><path className={`${darkMode ? 'fill-white' : 'fill-grey'}`} d="M17.5 1.25a.5.5 0 0 1 1 0v2.5H21a.5.5 0 0 1 0 1h-2.5v2.5a.5.5 0 0 1-1 0v-2.5H15a.5.5 0 0 1 0-1h2.5v-2.5zm-11 4.5a1 1 0 0 1 1-1H11a.5.5 0 0 0 0-1H7.5a2 2 0 0 0-2 2v14a.5.5 0 0 0 .8.4l5.7-4.4 5.7 4.4a.5.5 0 0 0 .8-.4v-8.5a.5.5 0 0 0-1 0v7.48l-5.2-4a.5.5 0 0 0-.6 0l-5.2 4V5.75z" fill="#000"></path></svg>)
            }
            {/* <i
                className={`${
                    isBookmarked ? "fa-solid" : "fa-regular"
                } fa-bookmark`}
            /> */}
        </button>
    );
}
