import { useEffect, useState } from "react";
import PublishOrganization from "./PublishOrganization";

export default function CreateOrganization() {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [blogContents, setBlogContents] = useState("");
    const [isEditing, setIsEditing] = useState(true);
    const [isPublishing, setIsPublishing] = useState(false);

    
    useEffect(() => {
        function onBeforeClose(e: BeforeUnloadEvent) {
            // if nothing was typed, close with no confirmation
            if (!title && !blogContents && !description) return;

            // confirm to close with unsaved changes
            e.preventDefault();
            e.returnValue =
                "Do you want to leave the site? Changes you made may not be saved.";
        }

        window.addEventListener("beforeunload", onBeforeClose);

        return () => window.removeEventListener("beforeunload", onBeforeClose);
    }, [blogContents, title, description]);

    return (
        <>
            <div>
                <PublishOrganization
                    onGoBack={() => setIsPublishing(false)}
                />
            </div>
        </>
    );
}
