import { useContext, useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";

import UserContext from "../../../UserContext";
import FollowingPosts from "./FollowingPosts";
import MostLikedPosts from "../../helper-components/MostLikedPosts";
import HomePageHeader from "./InnerHeader";
import { getTrendingPosts, getPostById } from "../../../firebase/firebase-app";
import SnipetPost from "../../../interfaces/SnipetPostInterface";
import TrendingPosts from "../../helper-components/TrendingPosts";
import { where } from "@firebase/firestore";


export default function Homepage() {
    const [search] = useSearchParams();
    const { user } = useContext(UserContext);
    const getBookmark = useRef<HTMLButtonElement>(null);

    // null feed means for you page
    const feed = search.get("feed");
    const [latestPosts, setLatestPosts] = useState<SnipetPost[] | null>(null);
    const [trendingPosts, setTrendingPosts] = useState<SnipetPost[] | null>(null);
    const orgExclOrgOptions = [
        where("excludeFromQuery", "==", false),
      ];

    async function taskRecentPosts() {
        if (!user?.bookmarks) return [];
        let bookmarked = await Promise.all(
            user.bookmarks.slice(0,5).map((postId) => getPostById(postId))
        );

        if (bookmarked && bookmarked.length) {
            const rp = {bookmarked, time: new Date().toISOString()};
            localStorage.setItem("bookmarked", JSON.stringify(rp));
        }
        
        return bookmarked;
    }

    async function taskTrendPosts() {
        let trendPosts:any = await getTrendingPosts();
        if (trendPosts && trendPosts.length) {
            const tp = {trendPosts, time: new Date().toISOString()};
            localStorage.setItem("trendPosts", JSON.stringify(tp));
        }
        return trendPosts;
    }

    async function refreshPosts () {
        // Fetch Recent Posts
        let sessionRecent = localStorage.getItem('bookmarked');
        let bookmarked:any[] = [];
        if (!sessionRecent) {
                bookmarked = await taskRecentPosts();
        } else {
            const rp = JSON.parse(sessionRecent);
            const now:any = new Date();
            const sessionTime:any = new Date(rp.time);
            const diffInHrs = (now - sessionTime) / (1000 * 60 * 60);
            bookmarked = diffInHrs > 1 ? await taskRecentPosts() : rp.bookmarked;
        }

        const mappedRecPosts: SnipetPost[] = bookmarked.map(doc => ({
            title: doc.title,
            description: doc.description,
            thumbnail: doc.thumbnail,
            authorUid: doc.authorUid,
            timestamp:doc.timestamp,
            id:doc.id,
            link: doc.link
        }));
        setLatestPosts(mappedRecPosts);
    }
    
    useEffect(() => {
        const fetchPosts = async () => {
            // Fetch Trending Posts
            let sessionTrend = localStorage.getItem('trendPosts');
            let trendPosts:any[] = [];
            if (!sessionTrend) {
                trendPosts = await taskTrendPosts();
            } else {
                const tp = JSON.parse(sessionTrend);
                const now:any = new Date();
                const sessionTime:any = new Date(tp.time);
                const diffInHrs = (now - sessionTime) / (1000 * 60 * 60);
                trendPosts = diffInHrs > 1 ? await taskTrendPosts() : tp.trendPosts;
            }

            const mappedTrendPosts: SnipetPost[] = trendPosts.map(doc => ({
                title: doc.title,
                description: doc.description,
                thumbnail: doc.thumbnail,
                authorUid: doc.authorUid,
                timestamp:doc.timestamp,
                id:doc.id,
                link: doc.link
            }));
            setTrendingPosts(mappedTrendPosts);
            setTimeout(() => {
                if (getBookmark.current) {
                    getBookmark.current.click();
                }
            }, 800);
        };
        fetchPosts();

    }, []);

    return (
        <div className="py-10 flex-1 home-screen">
            <HomePageHeader />
            <div className="flex lg:gap-8 w-full">
                <main className="pt-7 flex flex-1 flex-col lg:px-0 lg:pr-20 lg:border-r border-stone-200">
                    <nav
                        className={`pl-4 lg:pl-10 lg:pr-10 flex justify-evenly text-sm child:mr-8 dark:text-white font-bold`}
                    >
                        <ul className="grid grid-flow-col text-center text-gray-500 bg-gray-50 rounded-full p-1 w-full">
                            <li>
                                <Link to="/" className={`flex justify-center py-2 ${feed !== "following" ? 'bg-white rounded-full shadow text-indigo-900' : ''}`}>
                                    All Updates
                                </Link>
                            </li>
                            <li>
                                <Link to="?feed=following" className={`flex justify-center py-2 ${feed === "following" ? 'bg-white rounded-full shadow text-indigo-900' : ''}`}>Following</Link>
                            </li>
                        </ul>
                        {/*<Link to="?feed=following">Mentions</Link>*/}

                    </nav>

                    <div className="pl-4 lg:pl-10 lg:pr-10">
                        {feed === "following" ? (
                            <FollowingPosts following={user?.following} />
                        ) : (
                            <MostLikedPosts options={orgExclOrgOptions}/>
                        )}
                    </div>
                </main>

                <div className="pr-4 lg:pr-10 pt-7 w-[368px] flex-col hidden lg:flex">
                    {/* Trending posts*/}
                    {trendingPosts && trendingPosts.length ? <TrendingPosts trendingPosts={trendingPosts} /> : null}
                    
                    <div className={`bg-white dark:bg-darkBgPrimary dark:text-white rounded-md p-4 flex flex-col gap-3 ${latestPosts && latestPosts.length ? '' : 'invisible'}`}>
                        <p className=" text-[16px] mt-2 font-bold">Your library <button type="button" ref={getBookmark} id="getbookmarks" onClick={refreshPosts}><i className="text-sm fa fa-landmark fa-fw text-blue-500"></i></button></p>
                        {latestPosts?.map(post => (
                        <Link
                            to={post.link}
                            className="flex gap-2 text-[12px] items-center poppins-medium"
                        >
                            <img
                                src={post.thumbnail ? post.thumbnail : 'https://picsum.photos/80/80/?blur'}
                                alt=""
                                className="h-[35px] w-[35px] rounded-md"
                            />
                            <div>
                                <p className="line-clamp-2">
                                    {post.title}
                                </p>
                            </div>
                        </Link>
                        ))}
                        {latestPosts && latestPosts.length > 5 && (
                            <Link className="text-primaryDark text-sm hover:underline" to="/library">See full list</Link>
                        )}
                    </div>
                </div>

                {/*<Sidebar>
                    <div className="mt-10">
                        <h2 className="poppins-semibold mb-4">
                            Recommended topics
                        </h2>

                        <div className="flex flex-wrap">
                            {RECOMMENDED_TOPICS.map((topic) => (
                                <Topic
                                    topicName={topic}
                                    className="py-2 px-4 mr-2 mb-[10px] text-sm"
                                    key={topic}
                                />
                            ))}
                        </div>
                    </div>
                            </Sidebar>*/}
            </div>
        </div>
    );
}
